import React, { useEffect, useState } from "react";
import styles from "./MapsFilter.module.css";
import InputFilter from "../Inputs/InputFilter/InputFilter";
import { ReactComponent as FilterIcon } from "../../../../assets/img/mapsfilter.svg";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as DomenIco } from "../../../../assets/img/mapDomen.svg";
import { ReactComponent as PeriodIco } from "../../../../assets/img/mapDate.svg";
import { ReactComponent as StatusIco } from "../../../../assets/img/mapStatus.svg";
import { ReactComponent as BinIco } from "../../../../assets/img/mapBin.svg";
import { ReactComponent as TypeIco } from "../../../../assets/img/mapCard.svg";
import { ReactComponent as DollarIco } from "../../../../assets/img/mapDollar.svg";
import { ReactComponent as PartnerIco } from "../../../../assets/img/mapPartner.svg";
import { ReactComponent as GeoIco } from "../../../../assets/img/mapGeo.svg";
import { ReactComponent as KeyWordsIco } from "../../../../assets/img/mapKeywords.svg";
import { ReactComponent as DeleteIco } from "../../../../assets/img/mapCleanWords.svg";

import {
  getContent,
  hasNonEmptyArray,
  removeEmptyArrays,
} from "../../utils/index";
import SelectFilter from "../Inputs/SelectFilter/SelectFilter";
import GeoRadio from "../Inputs/geoRadio/GeoRadios";

import DateFilter from "../Inputs/DateFilter/DateFilter";
import RadioButton from "../Inputs/Radio/RadioButton";
import { getData } from "../../api";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";

const MapsFilter = ({
  setData,
  currentPage,
  rowsPerPage,
  selectedValues,
  setSelectedValues,
  setPageCountState,
  setAllDomains,
  setIsLoading,
  setFullLength,
}) => {
  const { t } = useTranslation();
  const [showAllFilters, setShowAllFilters] = useState(false);
  const { theme } = useTheme();

  const [itemsData, setItemsData] = useState({
    domains: [],
    bin: [],
    country: [],
    worker: [],
    partner: [],
  });

  useEffect(() => {
    const getFilterData = async () => {
      try {
        const { data } = await getData({
          url: "/get-cards-by-filter",
          body: {
            queryData: 1,
            count: 999999999,
          },
        });

        setAllDomains(data.map((domain) => domain.cc));

        const partner = await getData({
          url: "/get-partners",
        });

        setItemsData({
          domains: getContent(data, "domain"),
          bin: getContent(data, "bin"),
          country: getContent(data, "country_code"),
          partner: getContent(partner, "username") || [],
        });
      } catch (error) {
        console.error(error);
      }
    };

    getFilterData();
  }, []);

  async function HandleClick() {
    try {
      if (
        !hasNonEmptyArray(selectedValues) &&
        !hasNonEmptyArray(selectedValues.geo)
      ) {
        setIsLoading(true);
        const { data, page, pageCount, full_length } = await getData({
          url: "/get-cards-by-filter",
          body: {
            queryData: currentPage,
            count: rowsPerPage,
          },
        });
        setFullLength(full_length);
        setPageCountState(pageCount);
        setData(data);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      let filterBody = await removeEmptyArrays(selectedValues);
      const { data, page, pageCount, full_length } = await getData({
        url: "/get-cards-by-filter",
        body: {
          queryData: currentPage,
          count: rowsPerPage,
          ...filterBody,
        },
      });
      setFullLength(full_length);
      setPageCountState(pageCount);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const inputData = [
    {
      placeholder: t("Домен"),
      type: "text",
      svgIcon: (
        <DomenIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "domains",
      select: true,
      items: itemsData.domains,
    },
    {
      placeholder: t("Период прихода карты"),
      type: "string",
      svgIcon: (
        <PeriodIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "dates",
      date: true,
    },
    {
      placeholder: t("Гео"),
      type: "text",
      svgIcon: (
        <GeoIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "geo",
      radioGeo: true,
      items: itemsData.country,
    },
    {
      placeholder: t("Статус"),
      type: "text",
      svgIcon: (
        <StatusIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "status",
      radio: true,
      items: [t("Открыта"), t("Закрыта")],
    },
    {
      placeholder: t("Бин"),
      type: "text",
      svgIcon: (
        <BinIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "bins",
      open: true,
      select: true,
      items: itemsData.bin,
    },
    {
      placeholder: t("Вид карты"),
      type: "text",
      svgIcon: (
        <TypeIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "types",
      select: true,
      items: ["CLASSIC", "GOLD", "PLATINIUM"],
    },
    {
      placeholder: t("Платежна система"),
      type: "text",
      svgIcon: (
        <DollarIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "payment_type",
      select: true,
      items: ["VISA", "MASTERCARD", "DISCOVERY"],
    },
    {
      placeholder: t("Партнёр"),
      type: "text",
      svgIcon: (
        <PartnerIco
          style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }}
        />
      ),
      name: "partner",
      select: true,
      items: itemsData.partner,
    },

    {
      placeholder: t("Включить поиск по словам"),
      type: "text",
      svgIcon: (
        <KeyWordsIco
          style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }}
        />
      ),
      name: "include_keywords",
      large: true,
      select: false,
    },
    {
      placeholder: t("Исключить слова из поиска"),
      type: "text",
      svgIcon: (
        <DeleteIco style={{ stroke: theme === "dark" ? "white" : "#1E1E1E" }} />
      ),
      name: "exclude_keywords",
      large: true,
      select: false,
    },
  ];

  const filtersToShow = showAllFilters ? inputData : inputData.slice(0, 3);

  return (
    <>
      <div className={styles["input-container"]}>
        {filtersToShow.map((filter, index) =>
          filter.select ? (
            <div className={styles.filter_item}>
              <SelectFilter
                key={index}
                {...filter}
                setSelectedValues={setSelectedValues}
              />
            </div>
          ) : filter.radioGeo ? (
            <div className={styles.filter_item}>
              <GeoRadio
                key={index}
                {...filter}
                setSelectedValues={setSelectedValues}
              />{" "}
            </div>
          ) : filter.date ? (
            <div className={styles.filter_item}>
              <DateFilter
                key={index}
                {...filter}
                setSelectedValues={setSelectedValues}
              />{" "}
            </div>
          ) : filter.radio ? (
            <div className={styles.filter_item}>
              <RadioButton
                key={index}
                {...filter}
                setSelectedValues={setSelectedValues}
              />{" "}
            </div>
          ) : (
            <div className={styles.filter_item}>
              <InputFilter
                key={index}
                {...filter}
                setSelectedValues={setSelectedValues}
              />{" "}
            </div>
          )
        )}
      </div>
      <div className={styles["btn-container"]}>
        <Button
          onClick={() => setShowAllFilters(!showAllFilters)}
          style={{ backgroundColor: "#F3F3F3", padding: "11px" }}
          className={
            styles["filter-btn "] || (showAllFilters && styles["filterActive"])
          }
        >
          <FilterIcon />
        </Button>
        <Button onClick={HandleClick}>{t("Применить фильтры")}</Button>
      </div>
    </>
  );
};

export default MapsFilter;
