import React, { useState } from "react";
import styles from "./Search.module.css";
import { ReactComponent as SearchIcon } from "../../../../assets/img/searchIcon.svg";
import { Button } from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../utils/axiosInstance";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { ReactComponent as CloseIcon } from "../../../../assets/img/xIcon2.svg";

const searchData = async (domain) => {
  try {
    const { data } = await axiosInstance({
      url: "/search-proklads",
      method: "post",
      data: { domain },
    });

    return data.message;
  } catch (err) {
    console.log(err);
  }
};

const Search = ({ setProklads, fetchDataProklads, setIsLoading }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [search, setSearch] = useState("");

  const handleSearch = async () => {
    setIsLoading(true);
    let proklads;

    if (search) {
      proklads = await searchData(search);
      proklads ? setProklads([proklads]) : setProklads([]);
      setIsLoading(false);
      return;
    }

    await fetchDataProklads();
  };

  return (
    <div data-theme={theme} className={styles.container}>
      <div className={styles["wrapper-input"]}>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t("Поиск")}
          className={styles.input}
        />

        {search ? (
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => setSearch("")}
          />
        ) : (
          <SearchIcon />
        )}
      </div>
      <Button onClick={handleSearch} className={styles.button}>
        {t("Искать")}
      </Button>
    </div>
  );
};

export default Search;
