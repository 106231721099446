import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Auth from "./modules/AuthPage/Auth";
import ProfilePage from "./modules/ProfilePage/ProfilePage";
import ProtectedRoute from "./utils/Hoc/ProtectedRoute";
import AdminPage from "./modules/AdminPage/AdminPage";
import AppLayout from "./layouts/AppLayout";
import ProkladsPage from "./modules/ProkladsPage/ui/index";
import MapPage from "./modules/MapPage/index";

import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import DashboardPage from "./modules/DashboardPage/ui/index";
import DashboardDomainPage from "./modules/DashboardDomainPage/ui/index";

export const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <AppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "dashboard/:id",
        element: <DashboardDomainPage />,
      },
      {
        path: "gaskets",
        element: <ProkladsPage />,
      },
      {
        path: "maps",
        element: <MapPage />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <AppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <AdminPage />,
      },
    ],
  },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <AppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
    ],
  },
]);
