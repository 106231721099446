import { useEffect, useRef, useState } from "react";
import styles from "./CustomSelect.module.css";
import { ReactComponent as ArrowIcon } from "../../assets/img/selectArrow.svg";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import { CustomCheckbox } from "../Checkbox/Checkbox";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const CustomSelect = ({
  data,
  options,
  onChange,
  name,
  subName,
  icon,
  className,
  selectRow,
  selectAll,
  ...props
}) => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const btnRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !btnRef.current.contains(event.target)
    ) {
      toggleDropdown();
    }
  };

  const handleCheckbox = (value) => {
    onChange(value);
  };

  const handleCheckboxAll = () => {
    if (data.length === options.length) {
      onChange([]);
      return;
    }
    onChange(options.map((el) => el.value));
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      data-theme={theme}
      className={classNames(styles["custom-select"], className)}
      {...props}
    >
      <div ref={btnRef} className={styles.selected} onClick={toggleDropdown}>
        <div>
          <p>{name}</p>
          {selectRow?.length && selectRow.length < options.length ? (
            <p>
              {selectRow[0]}{" "}
              {selectRow?.length > 1 && (
                <span className={styles.circle}>+{selectRow?.length - 1}</span>
              )}
            </p>
          ) : data.length === options.length ? (
            <p> {subName}</p>
          ) : (
            <p> {subName}</p>
          )}
        </div>
        {icon || <ArrowIcon className={styles.arrow} />}
      </div>
      {isOpen && (
        <div ref={dropdownRef} className={styles.options}>
          {selectAll && (
            <label htmlFor={"all"} className={styles.option}>
              <CustomCheckbox
                isChecked={data.length === options.length}
                value={"all"}
                onChange={handleCheckboxAll}
                name={"all"}
              />
              <span>{t("Выбрать всех")}</span>
            </label>
          )}
          {options.map((option) => (
            <label
              htmlFor={option.value}
              key={option.value}
              className={styles.option}
            >
              <CustomCheckbox
                isChecked={data.includes(option.value)}
                value={option.value}
                onChange={handleCheckbox}
                name={option.value}
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
