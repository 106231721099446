import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/img/editDashbordTableIcon.svg";
import { ReactComponent as DwnldIcon } from "../../../../assets/img/download.svg";
import styles from "./DashboardDomainTable.module.css";
import { CustomCheckbox } from "../../../../components/Checkbox/Checkbox";
import { ReactComponent as TrashIcon } from "../../../../assets/img/trashMaps.svg";
import classNames from "classnames";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import { useTransition } from "react";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { TableSkeleton } from "./TableSkeleton";
import { SkeletonTable } from "../../../DashboardPage/ui/DashboardTable/SkeletonTable";

const StatusButton = ({ string, status }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles["status-btn"], {
        [styles.open]: status === "orange",
        [styles.close]: status === "green",
      })}
    >
      {t(string)}
    </div>
  );
};

export const DashboardDomainTable = ({
  fullLength,
  isLoading,
  data,
  selectedRow,
  onChange,
  onChangeHeader,
  handleChangeCcCard,
  handleDeleteCard,
  downloadCSV,
  rowsPerPage,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const headerData = [
    {
      name: (
        <CustomCheckbox
          onChange={(value, isChecked) => onChangeHeader(isChecked)}
          isChecked={selectedRow.length === fullLength}
        />
      ),

      width: 48,
    },
    {
      name: t("Бин"),
      width: 120,
    },
    {
      name: t("Гео"),
      width: 64,
    },
    {
      name: t("Дата получения"),
      width: 193,
    },
    {
      name: t("Вид карты"),
      width: 160,
    },
    {
      name: t("Статус"),
      width: 320,
    },
  ];

  return (
    <div data-theme={theme} className={styles["table-container"]}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headerData.map((el) => {
              return (
                <th
                  key={el.name}
                  onClick={el.onClick}
                  style={{ width: el.width }}
                >
                  {el.name}
                </th>
              );
            })}
            <th style={{ width: "144px" }}></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            Array.from({ length: rowsPerPage }).map((_, i) => (
              <TableSkeleton key={i} />
            ))
          ) : !data || !data.length ? (
            <tr>
              <td
                style={{ textAlign: "center" }}
                className={styles["not-found-td"]}
                colSpan={9}
              >
                {t("Нет данных")}
              </td>
            </tr>
          ) : (
            data.map((row, i) => {
              const createdAt = row.createdAt.split(" ");

              return (
                <tr key={row.cc}>
                  <td>
                    <CustomCheckbox
                      value={{ cc: row.cc, country_code: row.country_code }}
                      onChange={(value, isChecked) =>
                        onChange(value, isChecked)
                      }
                      isChecked={selectedRow.some((el) => el.cc === row.cc)}
                      name=""
                    />
                  </td>
                  <td>{row.bin}</td>
                  <td>
                    <div>
                      <img
                        style={{
                          width: 32,
                          height: 32,
                          marginLeft: 0,
                          borderRadius: "12px",
                        }}
                        src={`https://flagsapi.com/${row.country_code}/flat/32.png`}
                        alt={row.name}
                      />
                    </div>
                  </td>
                  <td>
                    <span>{createdAt[0]}</span>{" "}
                    <span style={{ color: "#aeaeb2" }}>{createdAt[1]}</span>
                  </td>
                  <td>{row.type}</td>
                  <td>
                    <StatusButton
                      string={row.opened ? "Открыта" : "Не открыта"}
                      status={row.opened ? "orange" : "green"}
                    />
                  </td>
                  <td>
                    <div style={{ position: "relative" }}>
                      <button onClick={() => handleChangeCcCard(row.cc)}>
                        <EditIcon
                          style={{ width: 15 }}
                          width={15}
                          height={15}
                        />
                      </button>
                      <button onClick={() => downloadCSV([row.cc])}>
                        <DwnldIcon stroke="#7B818A" />
                      </button>
                      <button onClick={() => handleDeleteCard(row.cc)}>
                        <TrashIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
