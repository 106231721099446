import { useState } from "react";
import styles from "./ChangeLanguage.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";

export const ChangeLanguage = ({ className }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const languageOptions = [
    { label: t("Русский"), value: "ru" },
    { label: t("Английский"), value: "en" },
  ];
  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleChangeLanguage = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className={className}>
      <div>
        {languageOptions.map((option) => (
          <label key={option.value} className={styles.radio_label}>
            <input
              type="radio"
              name="language"
              value={option.value}
              checked={selectedLanguage.includes(option.value)}
              onChange={handleChange}
            />
            <span
              className={`${styles["radio-custom"]} ${
                selectedLanguage.includes(option.value) ? styles.selected : ""
              }`}
            ></span>
            {option.label}
          </label>
        ))}{" "}
      </div>
      <div className={styles.button_languages}>
        <Button
          onClick={handleChangeLanguage}
          variant="outline"
          isActive={selectedLanguage !== i18n.language}
        >
          {t("Сохранить изменения")}
        </Button>
      </div>
    </div>
  );
};
