import React, { useState, useRef, useEffect } from "react";
import styles from "./SelectFilter.module.css";
import { ReactComponent as Search } from "../../../../../assets/img/searchIcon.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";
const SelectFilter = ({
  placeholder,
  svgIcon,
  name,
  setSelectedValues,
  items,
}) => {
  const [dataItems, setDataItems] = useState(items);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const { theme } = useTheme();
  useEffect(() => {
    setDataItems(items);
  }, [items]);

  function valueInclude(arr, value) {
    return arr.includes(value)
      ? arr.filter((item) => item !== value)
      : [...arr, value];
  }

  const handleDomainChange = (e) => {
    const value = e.target.value;
    const updatedDomains = valueInclude(selectedDomains, value);
    setSelectedDomains(updatedDomains);

    setSelectedValues((prev) => {
      return {
        ...prev,
        [name]: valueInclude(prev[name] || [], value),
      };
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const filterData = (e) => {
    const term = e.target.value.toLowerCase();
    const filteredItems = items.filter((item) =>
      item.toLowerCase().includes(term)
    );
    setDataItems(filteredItems);
    setSearchTerm(term);
  };

  const sortSelectedItems = () => {
    setDataItems((prevItems) => {
      const selected = prevItems.filter((item) =>
        selectedDomains.includes(item)
      );
      const unselected = prevItems.filter(
        (item) => !selectedDomains.includes(item)
      );
      return [...selected, ...unselected];
    });
  };

  const handleClearSelection = (e) => {
    e.stopPropagation();
    setSelectedDomains([]);
    setSelectedValues((prev) => {
      return {
        ...prev,
        [name]: [],
      };
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      sortSelectedItems();
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={styles["dropdown-container"]}
      ref={dropdownRef}
      data-theme={theme}
    >
      <div
        className={styles["dropdown-header"]}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          border:
            isOpen || selectedDomains.length > 0 ? "1px solid #1E1E1E" : "",
        }}
      >
        <span>{placeholder}</span>
        {selectedDomains.length > 0 && (
          <div className={styles["selected-count"]}>
            {selectedDomains.length}
          </div>
        )}
        <button className={styles["clear-btn"]} onClick={handleClearSelection}>
          {isOpen || selectedDomains.length > 0 ? "✕" : svgIcon}
        </button>
      </div>

      {isOpen && (
        <div className={styles["dropdown-body"]}>
          <div className={styles["search-container"]}>
            <input
              type="text"
              placeholder={t("Поиск")}
              value={searchTerm}
              onChange={filterData}
              className={styles["search-input"]}
            />
            <div className={styles["search-icon"]}>
              {searchTerm ? (
                <button
                  className={styles["clear-search"]}
                  onClick={() => {
                    setSearchTerm("");
                    setDataItems(items);
                  }}
                >
                  ✕
                </button>
              ) : (
                <Search style={{ stroke: "#7b818a " }} />
              )}
            </div>
          </div>
          <ul className={styles["dropdown-list"]}>
            {dataItems.map((domain, index) => (
              <li key={index} className={styles["dropdown-item"]}>
                <label className={styles["custom-checkbox"]}>
                  <input
                    type="checkbox"
                    value={domain}
                    onChange={handleDomainChange}
                    checked={selectedDomains.includes(domain)}
                    className={styles["checkbox"]}
                  />
                  <span className={styles["checkmark"]}></span>
                  {domain}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectFilter;
