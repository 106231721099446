import React, { useEffect, useState } from "react";
import styles from "./TableInfo.module.css";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as DeleteIcon } from "../../../../assets/img/trash.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/img/timeIcon.svg";
import axiosInstance from "../../../../utils/axiosInstance";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";

const TableInfo = ({ fullLength, deleteProklad, selectedRow, allProklads }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [expired, setExpired] = useState(0);

  useEffect(() => {
    const expiredArray = allProklads.filter((el) => {
      const today = moment();
      const dueDate = moment(el.endAt, "DD.MM.YYYY");
      const daysLeft = dueDate.diff(today, "days");

      if (daysLeft < 7) {
        return el;
      }
    });

    setExpired(expiredArray.length);
  }, [allProklads]);

  return (
    <div data-theme={theme} className={styles.container}>
      <div className={styles["block-left"]}>
        <div className={styles["all-proklads"]}>
          <span>
            {t("Всего прокладок")}: {fullLength}
          </span>
        </div>
        <div className={styles["expired"]}>
          <span>
            {t("Истекли")}: {expired}
          </span>
          <ClockIcon />
        </div>
      </div>

      <Button
        disabled={!selectedRow.length}
        onClick={() => deleteProklad(selectedRow)}
        className={styles["delete-button"]}
      >
        {t("Удалить")}
        <DeleteIcon stroke={theme === "dark" ? "black" : "#49454F"} />
      </Button>
    </div>
  );
};

export default TableInfo;
