import React, { useEffect, useState } from "react";
import MapsTable from "./ui/MapsTable/MapsTable";
import MapsHeader from "./ui/MapsHeader/MapsHeader";
import MapsFilter from "./ui/MapsFilter/MapsFilter";
import styles from "./Map.module.css";

import { useTheme } from "../../components/ui/ChangeTheme/context/ThemeContext";
import Aside from "../../components/Aside/Aside";
import { Notes } from "../../components/Notes/Notes";
import { useTranslation } from "react-i18next";
import { getData } from "./api";
import MapsModal from "./ui/MapsModal/MapsModal";
import { removeEmptyArrays } from "./utils";
import axiosInstance from "../../utils/axiosInstance";
import { useUserData } from "../../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
const rowsPerPageOptions = [15, 50, 100];

const MapPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isOpenAside, setIsOpenAside] = useState(false);
  const [domainUrl, setDomainUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [pageCountState, setPageCountState] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActiveDoubles, setIsActiveDoubles] = useState(0);
  const [allDomains, setAllDomains] = useState([]);
  const [fullLength, setFullLength] = useState(0);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { userData } = useUserData();

  const [selectedValues, setSelectedValues] = useState({
    domains: [],
    status: [],
    dates: [],
    bins: [],
    types: [],
    paymentSystem: [],
    partner: [],
    worker: [],
    geo: [{ country: [] }, { exclude_country: [] }],
    include_keywords: [],
    exclude_keywords: [],
    double: [0],
  });

  const checkAdmin = (role) => {
    return role > 5;
  };

  const [itemsData, setItemsData] = useState({
    domains: [],
    bin: [],
  });

  const handleOpenAside = (domain, bool) => {
    if (domainUrl !== domain) setDomainUrl(domain);
    setIsOpenAside(bool);
  };

  useEffect(() => {
    let filterBody = removeEmptyArrays(selectedValues);

    async function fetchData() {
      setisLoading(true);
      try {
        const { data, page, pageCount, total, full_length } = await getData({
          url: "/get-cards-by-filter",
          body: {
            queryData: currentPage,
            count: rowsPerPage,
            ...filterBody,
          },
        });
        setFullLength(full_length);
        setData(data);
        setPageCountState(pageCount);
        setisLoading(false);
        return;
      } catch (error) {
        setisLoading(false);
        console.error(error);
      }
    }
    checkAdmin(userData?.role) && fetchData();
  }, [isActiveDoubles, rowsPerPage, currentPage]);

  const downloadCSV = async (comments, format) => {
    try {
      const data = await axiosInstance({
        url: "/download-csv-without-domain",
        method: "post",
        data: {
          ccsData: allDomains,
          format: format ? `custom |${format}` : "format4",
          comment: comments,
        },
        responseType: "blob",
      });

      if (!data) {
        console.log("No data");
      }

      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = `cards.csv`;
      link.click();
      toast.success(t("Карты успешно скачаны!"));
    } catch (error) {
      toast.error(t("Ошибка при скачивании карт"));
      console.log(error.message);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" theme={theme} />
      {checkAdmin(userData?.role) ? (
        <div data-theme={theme} className={styles.container}>
          <MapsHeader
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setData={setData}
            isActiveDoubles={isActiveDoubles}
            setIsActiveDoubles={setIsActiveDoubles}
            setSelectedValues={setSelectedValues}
          />
          <MapsFilter
            data={data}
            setData={setData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            setSelectedValues={setSelectedValues}
            selectedValues={selectedValues}
            itemsData={itemsData}
            setItemsData={setItemsData}
            setPageCountState={setPageCountState}
            setAllDomains={setAllDomains}
            setIsLoading={setisLoading}
            setFullLength={setFullLength}
          />
          <MapsTable
            data={data}
            isLoading={isLoading}
            openAside={handleOpenAside}
            setData={setData}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCountState={pageCountState}
            fullLength={fullLength}
          />
          <Aside
            width={320}
            title={t("Заметки")}
            isOpen={isOpenAside}
            setIsOpen={setIsOpenAside}
          >
            <Notes
              url={"/get-comments"}
              typeRequest={"card"}
              refRequest={domainUrl}
            />
          </Aside>

          <MapsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            allDomains={allDomains}
            downloadCSV={downloadCSV}
          />
        </div>
      ) : (
        <h1>{t("Доступ запрещен")}</h1>
      )}
    </>
  );
};

export default MapPage;
