import React from "react";
import noCountry from "../../assets/img/noCountry.svg";
const NoCountry = (props) => (
  <svg
    {...props}
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "relative", top: "5px" }}
  >
    <defs>
      <clipPath id="roundedClip">
        <rect width="32" height="32" rx="4" ry="4" />
      </clipPath>
    </defs>
    <image
      href={noCountry}
      width="32"
      height="22"
      clipPath="url(#roundedClip)"
    />
  </svg>
);

export default NoCountry;
