import React from "react";
import styles from "./CheckBox.module.css";
import classNames from "classnames";

export const CustomCheckbox = ({ value, onChange, name, isChecked }) => {
  const handleCheckboxChange = () => {
    onChange(value, !isChecked);
  };
  return (
    <label className={styles["checkbox-container"]}>
      <input
        id={name}
        value={value}
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span
        className={classNames(styles["custom-checkbox"], {
          [styles.checked]: isChecked,
        })}
      ></span>
    </label>
  );
};
