import React, { useEffect, useState } from "react";
import styles from "./DashboardDomainCards.module.css";
import { DashboardDomainTable } from "../DashboardDomainTable";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as ButtonIcon } from "../../../../assets/img/dashboardDwnld.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/img/deleteIcon.svg";
import classNames from "classnames";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import Pagination from "../../../../components/Pagination/Pagination";
import { RowPerPage } from "../../../../components/RowPerPage/RowPerPage";
import { getData } from "../../../../api";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../utils/axiosInstance";
import MapsModal from "../../../MapPage/ui/MapsModal/MapsModal";
import { ReactComponent as CloseIcon } from "../../../../assets/img/xIcon.svg";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";

const rowsPerPageOptions = [15, 50, 100];

export const DashboardDomainCards = ({
  handleChangeCcCard,
  selectedRow,
  setSelectedRow,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const navData = [t("Основа"), t("Архив")];
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [navigation, setNavigation] = useState(navData[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const [pageCountState, setPageCountState] = useState(0);
  const { id } = useParams();
  const [isOpenModalDwnld, setOpenModalDwnld] = useState(false);
  const [fullLengthCards, setFullLengthCards] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenModalDwnldSelect, setOpenModalDwnldSelect] = useState(false);
  const [allCards, setAllCards] = useState([]);

  const getDomainsData = async () => {
    setIsLoading(true);
    const { data, page, pageCount, error } = await getData({
      url: "/get-cards",
      body: {
        archived: [1],
        domain: id,
        queryData: currentPage,
        count: rowsPerPage,
      },
    });

    if (error) {
      console.log(error);
      return;
    }
    setData(data);
    setDataTable(data);
    setPageCountState(pageCount);
    setIsLoading(false);
  };

  const handleSelectrdRowChange = (value) => {
    setSelectedRow((prev) => {
      return prev.some((el) => el.cc === value.cc)
        ? prev.filter((el) => el.cc !== value.cc)
        : [...prev, value];
    });
  };

  const handleHeaderCheckboxChange = async (isChecked) => {
    if (isChecked) {
      setSelectedRow(
        allCards.message.data.map((el) => ({
          cc: el.cc,
          country: el.country_card,
        }))
      );
    } else {
      setSelectedRow([]);
    }
  };
  useEffect(() => {
    const getAllCards = async () => {
      const { data: allRowNames } = await axiosInstance({
        url: "/get-cards-by-filter",
        method: "post",
        data: { domains: [id], all: true },
      });
      setAllCards(allRowNames);
      setFullLengthCards(allRowNames.message.full_length);
    };
    getAllCards();
  }, []);

  const handleDeleteCard = async (cc) => {
    const data = await getData({
      url: "/delete-card",
      body: { domain: id, ccsData: [cc] },
    });

    getDomainsData();
  };

  const handleDeleteSelectCard = async () => {
    await getData({
      url: "/delete-card",
      body: { domain: id, ccsData: selectedRow.map((el) => el.cc) },
    });
    getDomainsData();
  };

  const downloadCSVAll = async (comment, format) => {
    try {
      let ccsData;

      const { data: responseCards } = await axiosInstance({
        url: "/get-cards-by-filter",
        method: "post",
        data: { domains: ["test1.com"], all: true },
      });
      ccsData = responseCards.message.data.map((el) => el.cc);

      const response = await axiosInstance({
        url: "/download-csv-without-domain",
        method: "post",
        data: {
          ccsData: ccsData,
          format: format ? `custom |${format}` : "format4",
          comment: comment,
        },
        responseType: "blob",
      });

      if (!response) {
        console.log("No data");
      }

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = "DomainCards.csv";
      link.click();
    } catch (error) {
      console.log(error.message);
    }
  };

  const downloadCSV = async (ccs, format, comment) => {
    try {
      const response = await axiosInstance({
        url: "/download-csv",
        method: "post",
        data: {
          domain: id,
          ccsData: ccs,
          format: format ? `custom |${format}` : "format4",
          comment: comment,
        },

        responseType: "blob",
      });

      if (!response) {
        console.log("No data");
      }

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = "DomainCards.csv";
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDomainsData();
  }, [rowsPerPage, currentPage]);

  useEffect(() => {
    if (navigation === t("Архив")) {
      setDataTable(data.filter((el) => el.archived === 1));
    } else if (navigation === t("Основа")) {
      setDataTable(data);
    }
  }, [navigation, data]);

  return (
    <div data-theme={theme} className={styles.container}>
      <MapsModal
        downloadCSV={(comment, format) => downloadCSVAll(comment, format)}
        allDomains={data}
        isModalOpen={isOpenModalDwnld}
        setIsModalOpen={setOpenModalDwnld}
      />
      <MapsModal
        downloadCSV={(comment, format) =>
          downloadCSV(selectedRow.map((el) => el.cc, format, comment))
        }
        allDomains={data}
        isModalOpen={isOpenModalDwnldSelect}
        setIsModalOpen={setOpenModalDwnldSelect}
      />
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        selectedRow={selectedRow}
        handleDelete={handleDeleteSelectCard}
      />
      <div className={styles.header}>
        <h2>{t("Карты внутри домена")}</h2>{" "}
        <Button
          onClick={() => setOpenModalDwnld(true)}
          className={styles["save-all-button"]}
          // style={{ background: "transparent" }}
        >
          {t("Скачать все карты")}
          <ButtonIcon className={styles.icon} style={{ marginLeft: "8px" }} />
        </Button>
      </div>
      {/* <div className={styles.nav}>
        {navData.map((el) => (
          <button
            onClick={() => setNavigation(el)}
            key={el}
            className={classNames(navigation === el && styles.active)}
          >
            {el}
          </button>
        ))}
      </div> */}
      <div className={styles["container-buttons-tag"]}>
        {selectedRow.length > 0 ? (
          <div onClick={() => setSelectedRow([])} className={styles.tag}>
            <>
              <span>
                {t("Выбрано карт")}: {selectedRow.length}
              </span>{" "}
              <CloseIcon />
            </>
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles["buttons-wrapper"]}>
          <div className={styles["all-domains"]}>
            <span>
              {t("Всего карт")}: {pageCountState}
            </span>
          </div>
          <div className={styles["buttons-wrapper-right"]}>
            {" "}
            <Button
              disabled={!selectedRow.length}
              onClick={() => setOpenModalDwnldSelect(true)}
            >
              {t("Скачать")}
              <ButtonIcon
                className={styles["dwnld-icon"]}
                style={{
                  marginLeft: "10px",
                }}
              />
            </Button>
            <Button
              disabled={!selectedRow.length}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              {t("Удалить")}
              <DeleteIcon
                className={styles["delete-icon"]}
                style={{
                  marginLeft: "10px",
                  width: 13.5,
                  height: 15,
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      <DashboardDomainTable
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        downloadCSV={downloadCSV}
        fullLength={fullLengthCards}
        handleDeleteCard={handleDeleteCard}
        handleChangeCcCard={handleChangeCcCard}
        data={dataTable}
        selectedRow={selectedRow}
        onChange={handleSelectrdRowChange}
        onChangeHeader={handleHeaderCheckboxChange}
      />
      <div className={styles["pagination-wrapper"]}>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={pageCountState}
        />
        <div className={styles["row-per-page-wrapper"]}>
          {" "}
          <RowPerPage
            options={rowsPerPageOptions}
            selectedValue={rowsPerPage}
            onChange={setRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
