import React, { useEffect, useState } from "react";
import styles from "./Notes.module.css";
import { Button } from "../Button/Button";
import { OtherNotes } from "./OtherNotes";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";

import { Notification } from "../ui/Notification/Notification";
import { useUserData } from "../../context/UserProvider";
import { getData } from "../../api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { avatars } from "../../utils/Avatars/avatars";

export const Notes = ({ url, typeRequest, refRequest }) => {
  const { theme } = useTheme();
  const [noteText, setNoteText] = useState("");
  const [notes, setNotes] = useState([]);
  const { userData } = useUserData();
  const { t } = useTranslation();

  const getNotes = async () => {
    const data = await getData({
      url: url,
      body: {
        type: typeRequest,
        ref: refRequest,
      },
    });
    if (data.error) {
      console.log(data.error);
      return;
    }
    setNotes(data.reverse());
  };

  const addNote = async () => {
    const data = await getData({
      url: "/add-comment",
      body: {
        type: typeRequest,
        ref: refRequest,
        comment: noteText,
      },
    });
    if (data.error) {
      toast.error(t("Ошибка!"));
      return;
    }
    toast.success(t("Успех!"));

    setNoteText("");
    getNotes();
  };

  useEffect(() => {
    if (refRequest) getNotes();
  }, [refRequest]);

  return (
    <div data-theme={theme} className={styles.wrapper}>
      <div className={styles.noteWrapper}>
        <div className={styles.noteContainer}>
          <div className={styles.noteHeader}>
            <div className={styles.avatar}>
              {userData?.avatar ? avatars[userData.avatar % 10] : avatars[0]}
            </div>
            <strong>{userData?.username}</strong>
          </div>

          <textarea
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
            className={styles.textarea}
            placeholder={t("Начните печатать...")}
          ></textarea>
        </div>{" "}
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={() => setNoteText("")}
          >
            {t("Отменить")}
          </Button>
          <Button className={styles.submitButton} onClick={addNote}>
            {t("Отправить")}
          </Button>
        </div>
      </div>
      <h3 className={styles.otherNotesTitle}>{t("Другие заметки")}</h3>
      <div className={styles.wrapperOtherNotes}>
        {notes.map((note) => (
          <OtherNotes
            refRequest={refRequest}
            data={note}
            setData={setNotes}
            typeRequest={typeRequest}
            key={note.comment_id}
            theme={theme}
            avatars={avatars}
            userData={userData}
          />
        ))}
      </div>
    </div>
  );
};
