import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as ButtonIcon } from "../../../../assets/img/dashboardDwnld.svg";
import styles from "./MapsHeader.module.css";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
const MapsHeader = ({
  setIsModalOpen,
  setSelectedValues,
  isActiveDoubles,
  setIsActiveDoubles,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const navData = [t("Уникальные"), t("Дубли")];

  const handleClickDubles = (index) => {
    setIsActiveDoubles(index);
    setSelectedValues((prev) => ({
      ...prev,
      double: [index],
    }));
  };

  return (
    <>
      <div data-theme={theme} className={styles.header}>
        <h2>{t("Список и фильтрация карт")}</h2>{" "}
        <Button
          className={styles["btn-dwnld"]}
          style={{
            background: theme == "dark" ? "#CFBCFF" : "transparent",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          {t("Скачать все карты")} <ButtonIcon className={styles.icon} />
        </Button>
      </div>
      <div className={styles.nav}>
        {navData.map((el, index) => (
          <button
            onClick={() => {
              handleClickDubles(index);
              setIsActiveDoubles(index);
            }}
            key={el}
            className={classNames(isActiveDoubles === index && styles.active)}
          >
            {el}
          </button>
        ))}
      </div>
    </>
  );
};

export default MapsHeader;
