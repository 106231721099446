import {
  Button,
  ChangeLanguage,
  ChangeTheme,
  Input,
} from "../../components/ui";
import styles from "./ProfilePage.module.css";
import telegramImg from "../../assets/img/telegram.svg";
import { HelpButton } from "./ui/helpButton/helpButton";
import { useTheme } from "../../components/ui/ChangeTheme/context/ThemeContext";
import { useState } from "react";
import { EditTokens } from "./api/api";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../context/UserProvider";
import { getData } from "../MapPage/api";
import { toast, ToastContainer } from "react-toastify";

const ProfilePage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { userData } = useUserData();

  const [formData, setFormData] = useState({
    token: "",
    id: "",
  });

  const [passwordData, setPasswordData] = useState({
    username: userData?.username,
    password: "",
    newPassword: "",
    entryPassword: "",
  });

  const handleSubmit = async () => {
    if (formData.id.length || formData.token.length) {
      await EditTokens(formData.token, formData.id);
      setFormData({
        token: "",
        id: "",
      });
    }
  };

  const handlePasswordChange = async () => {
    if (
      passwordData.password &&
      passwordData.newPassword &&
      passwordData.newPassword === passwordData.entryPassword
    ) {
      try {
        const response = await getData({
          url: "/change-my-password",
          body: {
            password: passwordData.password,
            newPassword: passwordData.newPassword,
          },
        });

        const { accessToken } = response;

        if (accessToken) {
          document.cookie =
            "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

          document.cookie = `accessToken=${accessToken}; path=/; secure; samesite=strict`;

          setPasswordData({
            password: "",
            newPassword: "",
            entryPassword: "",
          });

          toast.success(t("Пароль успешно изменен!"));
          return "success";
        } else {
          console.error("accessToken отсутствует в ответе сервера");
          return "error";
        }
      } catch (error) {
        console.error("Ошибка при смене пароля:", error);
        toast.error(t("Ошибка при смене пароля."));
        return "error";
      }
    }
  };
  return (
    <>
      <ToastContainer position="top-center" theme={theme} />
      <div className={styles.container}>
        <div
          className={`${styles.item} ${
            theme === "dark" ? styles.item_dark : ""
          } ${styles.item_one}`}
        >
          <h1 className={styles.webhook_text}>{t("Настройка вебхука")} </h1>
          <form className={styles.form}>
            <div className={styles.token_block}>
              <Input
                placeholder={t("Токен бота")}
                value={formData.token}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    token: e.target.value,
                  });
                }}
              />
              <HelpButton className={styles.helpButton} />
            </div>
            <div className={styles["chatId_block"]}>
              <Input
                placeholder={t("ID чата")}
                className={styles.chatId_input}
                value={formData.id}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    id: e.target.value,
                  });
                }}
              />
              <HelpButton
                className={styles.helpButtonTwo}
                children={t(
                  "Создайте чат и скопируйте ID c помощью @chatIDrobot"
                )}
              />
            </div>
            <div className={styles.button}>
              <Button
                variant="outline"
                block={formData.block}
                type="button"
                isActive={Boolean(formData.token.length || formData.id.length)}
                onClick={() => handleSubmit()}
              >
                {t("Сохранить изменения")}
              </Button>
            </div>
          </form>
        </div>
        <div
          className={`${styles.item} ${
            theme === "dark" ? styles.item_dark : ""
          } ${styles.item_two}`}
        >
          <h1 className={styles.changeLanguage_text}>{t("Смена языка")}</h1>
          <ChangeLanguage className={styles.changeLanguage} />
        </div>
        <div
          className={`${styles.item} ${
            theme === "dark" ? styles.item_dark : ""
          } ${styles.item_three}`}
        >
          <ChangeTheme className={styles.changeTheme} />
        </div>
        <div
          className={`${styles.item} ${
            theme === "dark" ? styles.item_dark : ""
          } ${styles.item_four}`}
        >
          <h1 className={styles.password_text}>{t("Смена пароля")}</h1>
          <form className={styles.form}>
            <div className={styles.passDiv}>
              <Input
                type="password"
                placeholder={t("Текущий пароль")}
                value={passwordData.password || ""}
                onChange={(e) =>
                  setPasswordData({ ...passwordData, password: e.target.value })
                }
              />
            </div>
            <div className={styles.passDiv}>
              <Input
                type="password"
                className={styles.newPassword_input}
                placeholder={t("Новый пароль")}
                value={passwordData.newPassword || ""}
                onChange={(e) =>
                  setPasswordData({
                    ...passwordData,
                    newPassword: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.passDiv}>
              <Input
                type="password"
                value={passwordData.entryPassword || ""}
                onChange={(e) =>
                  setPasswordData({
                    ...passwordData,
                    entryPassword: e.target.value,
                  })
                }
                className={styles.repeatNewPassword_input}
                placeholder={t("Подтвердите новый пароль")}
              />
            </div>
            <div className={styles.button}>
              <Button
                variant="outline"
                onClick={handlePasswordChange}
                isActive={
                  passwordData.password.length ||
                  passwordData.newPassword.length
                }
              >
                {t("Сохранить изменения")}
              </Button>
            </div>
          </form>
        </div>
        <div
          className={`${styles.item} ${
            theme === "dark" ? styles.item_dark : ""
          } ${styles.item_five}`}
        >
          <h1 className={styles.contacts_text}>{t("Контакты")}</h1>
          <div className={styles.telegram_block}>
            <img src={telegramImg} alt="telegram" />
            <h2 className={styles.bot_name}>@tgbotsupportname</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
