import React from "react";
import styles from "./Skeleton.module.css";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";

const Skeleton = () => {
  const { theme } = useTheme();
  return (
    <tr className={styles.skeleton} data-theme={theme}>
      <td className={styles.cell}>
        <div className={styles.checkbox} />
      </td>
      <td className={styles.cell}>
        <div className={styles.text} />
      </td>
      <td className={styles.cell}>
        <div className={styles.flag} />
      </td>
      <td className={styles.cell}>
        <div className={styles.text} />
      </td>
      <td className={styles.cell}>
        <div className={styles.text} />
      </td>
      <td className={styles.cell}>
        <div className={styles.status} />
      </td>
      <td className={styles.cell}>
        <div className={styles.actions}>
          <div className={styles.icon} />
          <div className={styles.icon} />
          <div className={styles.icon} />
        </div>
      </td>
    </tr>
  );
};

export default Skeleton;
