import React, { useState, useRef, Children, useEffect } from "react";

import styles from "./submenuDots.module.css";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import classNames from "classnames";
import { ReactComponent as DotsIcon } from "../../assets/img/dotsDashboardIcon.svg";

const SubmenuDots = ({ children, isOpenReverse }) => {
  const { theme } = useTheme();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      btnRef.current &&
      !btnRef.current.contains(event.target)
    ) {
      toggleDropdown();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <button ref={btnRef} onClick={toggleDropdown}>
        <DotsIcon />
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          data-theme={theme}
          className={classNames(styles.dropdownMenu, {
            [styles.reverse]: isOpenReverse,
          })}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default SubmenuDots;
