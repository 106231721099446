import classNames from "classnames";
import styles from "./Button.module.css";
import { useTheme } from "../ChangeTheme/context/ThemeContext";

export const Button = ({
  type = "button",
  className,
  children,
  variant,
  onClick,
  block = false,
  EndIcon,
  startIcon,
  isActive,
  ...props
}) => {
  const { theme } = useTheme();
  return (
    <div className={classNames(styles.container)} data-theme={theme}>
      <button
        className={classNames(
          { [styles.active]: isActive },
          styles[variant === "outline" ? "outline" : "button"],
          className
        )}
        type={type}
        onClick={onClick}
        disabled={block}
        {...props}
      >
        {startIcon && (
          <img src={startIcon} alt="start" className={styles.startIcon} />
        )}
        {children}
        {EndIcon && (
          <EndIcon
            style={{ stroke: theme === "dark" ? "white" : "#1e1e1e" }}
            className={styles.endIcon}
          />
        )}
      </button>
    </div>
  );
};
