import React, { useState, useRef, useEffect } from "react";
import styles from "./radioButton.module.css";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";

const RadioButton2 = ({
  placeholder,
  name,
  setSelectedValues,
  items,
  svgIcon,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();

  const handleValueChange = (value) => {
    const mappedValue = value === items[0] ? items[0] : items[1];
    setSelectedValue(value);
    setSelectedValues({ [name]: mappedValue });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      data-theme={theme}
      className={styles["dropdown-container"]}
      ref={dropdownRef}
    >
      <div
        className={styles["dropdown-header"]}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          border:
            isOpen || selectedValue !== null
              ? "1px solid rgb(30, 30, 30)"
              : "1px solid #ccc",
        }}
      >
        <span>{selectedValue ? selectedValue : placeholder}</span>

        <button
          className={styles["clear-btn"]}
          onClick={() => {
            setSelectedValue(null);
            setSelectedValues((prev) => ({
              ...prev,
              [name]: null,
            }));
          }}
        >
          {isOpen || selectedValue !== null ? "✕" : svgIcon}
        </button>
      </div>

      {isOpen && (
        <div className={styles["dropdown-body"]}>
          <ul className={styles["dropdown-list"]}>
            {items.map((item) => (
              <li key={item} className={styles["dropdown-item"]}>
                <label className={styles["custom-radio"]}>
                  <input
                    type="radio"
                    name={name}
                    value={item}
                    onChange={() => handleValueChange(item)}
                    checked={selectedValue === item} // Проверяем через сравнение значений
                  />
                  <span className={styles["radio-checkmark"]}></span>
                  <span className={styles["radio-text"]}>{item}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RadioButton2;
