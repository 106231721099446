import { toast } from "react-toastify";
import { getData } from "../api";

export const hasNonEmptyArray = (obj) =>
  Object.values(obj).some((arr) => Array.isArray(arr) && arr.length > 0);

export function removeEmptyArrays(obj) {
  if (obj == null) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        if (key === "geo" && Array.isArray(value)) {
          const flatGeo = value.reduce((acc, item) => {
            if (typeof item === "object" && item !== null) {
              return { ...acc, ...item };
            }
            return acc;
          }, {});
          return [key, removeEmptyArrays(flatGeo)];
        }

        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          value = removeEmptyArrays(value);
        }

        if (typeof value === "number") {
          value = [value];
        }

        return [key, value];
      })
      .filter(([key, value]) => {
        if (key === "geo" && typeof value === "object") {
          return Object.keys(value).length > 0;
        }
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        if (typeof value === "object" && value !== null) {
          return Object.keys(value).length > 0;
        }
        return value !== null;
      })
      .flatMap(([key, value]) => {
        if (key === "geo" && typeof value === "object") {
          return Object.entries(value);
        }
        return [[key, value]];
      })
  );
}

export const getContent = (data, value) => {
  return Array.from(new Set(data.map((el) => el[value])));
};
