import React, { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../utils/axiosInstance";
import Loader from "../components/Loader/Loader";
import Cookies from "js-cookie";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const token = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const { data } = await axiosInstance({
          url: "/me",
          method: "post",
        });

        setUserData(data);
        setIsLoading(false);
      } catch (err) {
        if (err.response.data.message) {
          setIsLoading(false);
          return { error: err.response.data.message };
        }
        return { error: err.message };
      } finally {
        setIsLoading(false);
      }
    }

    if (token) {
      getData();
    } else {
      setIsLoading(false);
    }
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <UserContext.Provider value={{ userData, setUserData, setIsLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
