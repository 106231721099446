import React from "react";
import styles from "./DeleteModal.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { ReactComponent as ButtonIcon } from "../../assets/img/trash.svg";
const DeleteModal = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  selectedRow,
  handleDelete,
}) => {
  const { t } = useTranslation();

  const handleOverlayClick = (e) => {
    if (e.target.className.includes(styles.overlay)) {
      setIsDeleteModalOpen(false);
    }
  };

  const getCardEnding = (count) => {
    if (count === 1) {
      return t("карту");
    } else if (count % 10 === 1 && count % 100 !== 11) {
      return t("карта");
    } else if (
      count % 10 >= 2 &&
      count % 10 <= 4 &&
      (count % 100 < 12 || count % 100 > 14)
    ) {
      return t("карты");
    } else {
      return t("карт");
    }
  };

  return (
    <>
      {isDeleteModalOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.modal}>
            <h2 className={styles.title}>
              {t("Вы действительно хотите удалить") +
                " " +
                selectedRow.length +
                " " +
                getCardEnding(selectedRow.length)}
              ?
            </h2>
            <div className={styles.footer}>
              <Button
                onClick={() => setIsDeleteModalOpen(false)}
                className={styles.cancelButton}
              >
                <span>{t("Отменить")}</span>
              </Button>
              <Button
                onClick={() => {
                  handleDelete(selectedRow);
                  setIsDeleteModalOpen(false);
                }}
                className={styles.downloadButton}
                style={{ width: "108px", gap: "10px" }}
              >
                {t("Удалить")}
                <ButtonIcon style={{ stroke: "#7B818A", width: "18px" }} />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteModal;
