import React from "react";
import styles from "./DomainSkeleton.module.css";
const DomainSkeleton = ({ theme }) => {
  return (
    <div className={styles[theme === "dark" ? "dark_skeleton" : "skeleton"]}>
      <div className={styles.skeleton_info}>
        <div className={styles.skeleton_text}></div>
      </div>
      <div className={styles.skeleton_info}>
        <div className={styles.skeleton_text}></div>
      </div>
      <div className={styles.skeleton_info}>
        <div className={styles.skeleton_text}></div>
      </div>
      <div className={styles.skeleton_info}>
        <div className={styles.skeleton_text}></div>
      </div>
      <div className={styles.skeleton_info}>
        <div className={styles.skeleton_text}></div>
      </div>
      <div className={styles.skeleton_interface}>
        <div className={styles.skeleton_button}></div>
        <div className={styles.skeleton_button}></div>
      </div>
    </div>
  );
};

export default DomainSkeleton;
