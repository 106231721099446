import React, { useState } from "react";
import styles from "./RowPerPage.module.css";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "../../assets/img/upIcon.svg";
import { useTranslation } from "react-i18next";

export const RowPerPage = ({ options, selectedValue, onChange }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div className={styles["custom-select-container"]}>
      <label className={styles["custom-select-label"]}>
        {t("Строк на странице")}
      </label>
      <div
        className={classNames(styles["custom-select"], {
          [styles.open]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedValue}</span>
        <div className={styles["arrow"]}>
          <ArrowIcon />
          <ArrowIcon style={{ transform: "rotate(180deg)" }} />
        </div>
        {isOpen && (
          <ul className={styles["options-list"]}>
            {options.map((option) => (
              <li
                key={option}
                className={classNames(styles["option-item"], {
                  [styles.selected]: option === selectedValue,
                })}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
