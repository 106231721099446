import { useEffect, useState } from "react";
import { DashBoardChartCountry } from "../../../components/DashboardChartCountry/DashBoardChartCountry";
import styles from "./DashboardDomain.module.css";
import { DashboardDomainCards } from "./DashboardDomainCards";
import { getData } from "../../DashboardPage/api";
import { useTranslation } from "react-i18next";
import Aside from "../../../components/Aside/Aside";
import { Notes } from "../../../components/Notes/Notes";
import { useParams } from "react-router-dom";

const DashboardDomainPage = () => {
  const { t } = useTranslation();
  const dataForChartChanger = [
    {
      name: t("День"),
      value: "day",
    },
    {
      name: t("Месяц"),
      value: "month",
    },
    {
      name: t("Год"),
      value: "year",
    },
    {
      name: t("Все время"),
      value: "all_time",
    },
  ];
  const [chartDataCountry, setChartDataCountry] = useState([]);
  const [selectedChartCountry, setSelectedChartCountry] = useState(
    dataForChartChanger[dataForChartChanger.length - 1].value
  );
  const [isOpenAsideNotes, setIsOpenAsideNotes] = useState(false);
  const [ccCard, setCcCard] = useState(null);
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState([]);
  const [countrySearch, setCountrySearch] = useState([]);

  const handleChangeCcCard = (cc) => {
    if (ccCard !== cc) setCcCard(cc);
    setIsOpenAsideNotes(true);
  };

  useEffect(() => {
    setCountrySearch([...new Set(selectedRow.map((el) => el.country_code))]);
  }, [selectedRow]);

  useEffect(() => {
    const getChartCountryData = async () => {
      const data = await getData({
        url: "/filter-by-country-code",
        body: {
          domain: id,
          country: countrySearch,
          date: selectedChartCountry,
        },
      });

      if (data.error) {
        console.log(data.error);
        return;
      }

      let res = Object.keys(data).map((el) => ({
        name: el,
        value: data[el],
        flag: `https://flagsapi.com/${el}/flat/64.png`,
      }));

      setChartDataCountry(res);
    };
    if (countrySearch.length > 1) {
      getChartCountryData();
    }
  }, [selectedChartCountry, countrySearch]);

  return (
    <div className={styles.container}>
      {countrySearch.length > 1 && (
        <DashBoardChartCountry
          chartData={chartDataCountry}
          dataForChartChanger={dataForChartChanger}
          selectedChart={selectedChartCountry}
          setSelectedChart={setSelectedChartCountry}
        />
      )}

      <DashboardDomainCards
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleChangeCcCard={handleChangeCcCard}
      />
      <Aside
        width={320}
        title={t("Заметки")}
        isOpen={isOpenAsideNotes}
        setIsOpen={setIsOpenAsideNotes}
      >
        <Notes url={"/get-comments"} typeRequest={"card"} refRequest={ccCard} />
      </Aside>
    </div>
  );
};

export default DashboardDomainPage;
