import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Api.module.css";
import { Button } from "../../../../components/ui";
import { ReactComponent as ButtonIcon } from "../../../../assets/img/Repeat.svg";
import { getData } from "../../../../api";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
const ApiModal = ({ isApiModalOpen, setApiModalOpen, handleApiChange }) => {
  const { t } = useTranslation();
  const [apiValue, setApiValue] = useState("");
  const [uId, setUiD] = useState("");
  const { theme } = useTheme();
  const handleOverlayClick = (e) => {
    if (e.target.className.includes(styles.overlay)) {
      setApiModalOpen(false);
    }
  };

  useEffect(() => {
    isApiModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isApiModalOpen]);

  useEffect(() => {
    const getApiKey = async () => {
      try {
        const data = await getData({
          url: "/get-api-key",
          body: {},
        });
        data.forEach((el) => setUiD(el.uid));
      } catch (error) {
        console.log(error);
      }
    };

    getApiKey();
  }, []);
  return (
    <>
      {isApiModalOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.modal} data-theme={theme}>
            <h2 className={styles.title}>{t("Смена Api ключа")}</h2>
            <label className={styles.label}>
              API KEY
              <input
                className={styles.input}
                placeholder={t("Введите новый ключ")}
                value={apiValue}
                onChange={(e) => setApiValue(e.target.value)}
              />
            </label>
            <label className={styles.label}>
              UID
              <input
                className={styles.input}
                placeholder={t("Введите новый uid")}
                value={uId}
                onChange={(e) => setUiD(e.target.value)}
              />
            </label>
            <div className={styles.footer}>
              <Button
                onClick={() => setApiModalOpen(false)}
                className={styles.cancelButton}
              >
                <span>{t("Отменить")}</span>
              </Button>
              <Button
                onClick={() => {
                  handleApiChange(apiValue, uId);
                  setApiValue("");
                  setApiModalOpen(false);
                }}
                className={styles.downloadButton}
                style={{ width: "108px", gap: "10px" }}
              >
                {t("Изменить")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApiModal;
