import { useEffect, useState } from "react";
import { Button, Input } from "../../components/ui";
import styles from "./Auth.module.css";
import { Api } from "./api/api";
import CardsImg from "../../assets/img/cards.svg";
import { ReactComponent as UserImg } from "../../assets/img/User.svg";
import { useTheme } from "../../components/ui/ChangeTheme/context/ThemeContext";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../context/UserProvider";

const Auth = () => {
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { theme } = useTheme();
  const navigate = useNavigate();
  const { setIsLoading } = useUserData();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const result = await Api(formData.login, formData.password);

    setLoading(false);

    if (result === "success") {
      setIsLoading(true);
      navigate("/");
      return;
    }
    toast.error(t("Пользователь с такими данными не найден!"));

    console.log("error");
  };

  return (
    <div>
      <ToastContainer position="top-center" theme={theme} />
      <div className={styles.container}>
        <div className={styles[theme === "dark" ? "dark" : "main"]}>
          <img alt="cards" src={CardsImg} className={styles.cardsImg} />
          <h1 className={styles.text}>
            {loading ? t("Загрузка...") : t("Вход в аккаунт")}
          </h1>
          <form className={styles.form} onSubmit={handleSubmit}>
            <Input
              placeholder={t("Логин")}
              value={formData.login}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  login: e.target.value,
                })
              }
              EndIcon={UserImg}
            />
            <Input
              style={{ marginTop: "24px" }}
              placeholder={t("Пароль")}
              className={styles.password}
              type="password"
              value={formData.password}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  password: e.target.value,
                })
              }
            />
            <div className={styles.button}>
              <Button type="submit">{t("Войти")}</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
