import axiosInstance from "../../../utils/axiosInstance";

export const Api = async (login, password) => {
  try {
    const response = await axiosInstance.post("/login", {
      login: login.trim(),
      password: password,
    });

    const { accessToken } = response.data;

    if (accessToken) {
      document.cookie = `accessToken=${accessToken}; path=/;`;
      return "success";
    } else {
      console.error("accesstoken отсутствует ");
      return "error";
    }
  } catch (error) {
    console.error("ошибка при запросе:", error);
    return "error";
  }
};
