import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useUserData } from "../../context/UserProvider";

const ProtectedRoute = ({ children }) => {
  const token = Cookies.get("accessToken");
  const [isAuth, setIsAuth] = useState(token);
  const { userData, setUserData } = useUserData();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axiosInstance({
          url: "/me",
          method: "post",
        });

        if (data === "You didn't authorized") {
          setIsAuth(false);
        } else {
          setIsAuth(true);
          setUserData(data);
        }
      } catch (error) {
        if (error.response?.data.message === "You didn't authorized") {
          setIsAuth(false);
        } else {
          console.error("Ошибка:", error);
        }
      }
    };

    checkAuth();
  }, []);

  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
