import React, { useEffect, useState } from "react";
import styles from "./AddPads.module.css";
import { ReactComponent as UrlIco } from "../../../../assets/img/urlIcon.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDownIco } from "../../../../assets/img/arrowDown.svg";
import { ReactComponent as DomenIco } from "../../../../assets/img/mapDomen.svg";
import { ReactComponent as PeriodIco } from "../../../../assets/img/mapDate.svg";
import { ReactComponent as StatusIco } from "../../../../assets/img/mapStatus.svg";
import { ReactComponent as GeoIco } from "../../../../assets/img/mapGeo.svg";
import DateFilter from "../Input/DateFilter/DateFilter";
import RadioButton2 from "../Input/Radio2/RadioButton2";
import RadioButton from "../Input/RadioButton/RadioButton";
import SelectOne from "../../../../components/SelectOne/SelectOne";
import axiosInstance from "../../../../utils/axiosInstance";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { toast } from "react-toastify";

export default function ModalOfAddPads({ onClose, fetchDataProklads }) {
  const { t } = useTranslation();
  const [addProkladsForms, setAddProkladsForms] = useState({
    [new Date()]: {},
  });
  const [countries, setCountries] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  const addForm = () => {
    setAddProkladsForms((prev) => ({
      ...prev,
      [new Date()]: {},
    }));
  };

  useEffect(() => {
    const getData = async () => {
      const { data: countries } = await axiosInstance({
        url: "/get-countries",
        method: "post",
      });
      const countriesArray = countries.message.map((el) => ({
        domain: el,
      }));
      setCountries(countriesArray);
    };
    getData();
  }, []);

  const handleAdd = async () => {
    try {
      Object.keys(addProkladsForms).forEach(async (item) => {
        const { host, type, geo, comment, endAt, total, is_active } =
          addProkladsForms[item];

        if (
          !host ||
          !type ||
          !geo ||
          // !comment ||
          !endAt
        ) {
          toast.error(`Все поля должны быть заполнены для элемента`);
          return;
        }

        const result = await axiosInstance.post("/add-proklad", {
          host,
          type,
          geo,
          comment: comment || "",
          endAt,
          total: 0,
          is_active: 1,
        });

        if (result.data.message === "Added") {
          toast.success(t("Успех!"));
          setIsAdded((prev) => !prev);
          fetchDataProklads();
          setAddProkladsForms((prev) => ({
            [new Date()]: {},
          }));

          return;
        }
      });
    } catch (error) {
      console.log(error.message || error);
      toast.error(t("Ошибка!"));
    }
  };

  return (
    <>
      <div className={styles.modalInner}>
        <div className={styles.modalTop}>
          <div className={styles.modalMain}>
            {Object.keys(addProkladsForms).map((el, i) => {
              return (
                <Form
                  countries={countries}
                  key={`${i}-${isAdded ? true : false}`}
                  id={el}
                  setAddProkladsForms={setAddProkladsForms}
                />
              );
            })}

            <button className={styles.addPadsBtn} onClick={addForm}>
              +
            </button>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            className={styles.cancelBtn}
            onClick={() => {
              setAddProkladsForms((prev) => ({
                [new Date()]: {},
              }));
              setIsAdded((prev) => !prev);
            }}
          >
            {t("Отменить")}
          </button>
          <button className={styles.saveBtn} onClick={handleAdd}>
            {t("Сохранить")}
          </button>
        </div>
      </div>
    </>
  );
}

const Form = ({ id, setAddProkladsForms, countries, isAdded }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [url, setUrl] = useState("");
  const [geo, setGeo] = useState("");
  const [totalDomains, setTotalDomains] = useState("");
  const [comment, setComment] = useState("");

  const inputData = [
    // {
    //   placeholder: t("Домен"),
    //   type: "text",
    //   svgIcon: <ArrowDownIco />,
    //   name: "domains",
    //   select: true,
    //   options: [
    //     { label: "test1", value: "test1" },
    //     { label: "test2", value: "test2" },
    //     { label: "domain.name.103.org", value: "domain.name.103.org" },
    //     { label: "domain.name.104.org", value: "domain.name.104.org" },
    //   ],
    // },
    {
      placeholder: t("Дата истечения"),
      type: "string",
      svgIcon: <PeriodIco stroke={theme === "dark" ? "white" : "black"} />,
      name: "dates",
      date: true,
    },
    {
      placeholder: t("Гео"),
      svgIcon: <GeoIco stroke={theme === "dark" ? "white" : "black"} />,
      name: "geo",
      radioGeo: true,
      items: countries,
    },
    // {
    //   placeholder: t("Статус"),
    //   type: "text",
    //   svgIcon: <StatusIco stroke={theme === "dark" ? "white" : "black"} />,
    //   name: "is_active",
    //   radio: true,
    //   items: [t("Активный"), t("Неактивный")],
    // },
    {
      placeholder: t("Тип"),
      type: "text",
      svgIcon: <ArrowDownIco stroke={theme === "dark" ? "white" : "black"} />,
      name: "type",
      radio: true,
      items: [t("IN"), t("OUT")],
    },
  ];

  const filtersToShow = inputData || inputData.slice(0, 3);

  useEffect(() => {
    setUrl("");
    setGeo("");
    setTotalDomains("");
    setComment("");
  }, [isAdded]);

  return (
    <div data-theme={theme} className={styles.addField}>
      <div className={styles.enterUrl}>
        <input
          className={styles.input}
          type="text"
          placeholder={t("URL прокладки")}
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);

            setAddProkladsForms((prev) => {
              return {
                ...prev,
                [id]: {
                  ...prev[id],
                  host: e.target.value,
                },
              };
            });
          }}
        />
        <UrlIco stroke={theme === "dark" ? "white" : "black"} />
      </div>
      {filtersToShow.map((filter, index) =>
        // filter.select ? (
        //   <SelectOne
        //     style={{ width: "100%", padding: "12.5px 16px" }}
        //     key={index}
        //     subName={filter.placeholder}
        //     options={filter.options}
        //     onChange={(e) =>
        //       setAddProkladsForms((prev) => {
        //         return {
        //           ...prev,
        //           [id]: {
        //             ...prev[id],
        //             host: e,
        //           },
        //         };
        //       })
        //     }
        //   />
        // )
        filter.radioGeo ? (
          <div className={styles.enterUrl}>
            <input
              className={styles.input}
              type="text"
              placeholder={t("Гео")}
              value={geo}
              onChange={(e) => {
                setGeo(e.target.value);

                setAddProkladsForms((prev) => {
                  return {
                    ...prev,
                    [id]: {
                      ...prev[id],
                      geo: e.target.value,
                    },
                  };
                });
              }}
            />
            <GeoIco stroke={theme === "dark" ? "white" : "black"} />
          </div>
        ) : filter.date ? (
          <DateFilter
            key={index}
            placeholder={filter.placeholder}
            svgIcon={filter.svgIcon}
            setSelectedValues={(e) =>
              setAddProkladsForms((prev) => {
                return {
                  ...prev,
                  [id]: {
                    ...prev[id],
                    endAt: e,
                  },
                };
              })
            }
          />
        ) : filter.radio ? (
          <RadioButton2
            key={index}
            placeholder={filter.placeholder}
            svgIcon={filter.svgIcon}
            name={filter.name}
            items={filter.items}
            setSelectedValues={(e) =>
              setAddProkladsForms((prev) => {
                return {
                  ...prev,
                  [id]: {
                    ...prev[id],
                    ...e,
                  },
                };
              })
            }
          />
        ) : filter.type ? (
          <RadioButton2
            key={index}
            placeholder={filter.placeholder}
            svgIcon={filter.svgIcon}
            name={filter.name}
            items={filter.items}
            setSelectedValues={(e) =>
              setAddProkladsForms((prev) => {
                return {
                  ...prev,
                  [id]: {
                    ...prev[id],
                    ...e,
                  },
                };
              })
            }
          />
        ) : (
          ""
        )
      )}
      {/* <div className={styles.enterUrl}>
        <input
          className={styles.input}
          type="text"
          placeholder={t("Кол-во доменов")}
          value={totalDomains}
          onChange={(e) => {
            setTotalDomains(e.target.value);

            setAddProkladsForms((prev) => {
              return {
                ...prev,
                [id]: {
                  ...prev[id],
                  total: e.target.value,
                },
              };
            });
          }}
        />
        <DomenIco stroke={theme === "dark" ? "white" : "black"} />
      </div> */}
      <div className={styles.enterUrl}>
        <input
          className={styles.input}
          type="text"
          placeholder={t("Комментарий")}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            setAddProkladsForms((prev) => {
              return {
                ...prev,
                [id]: {
                  ...prev[id],
                  comment: e.target.value,
                },
              };
            });
          }}
        />
      </div>
    </div>
  );
};
