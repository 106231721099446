import styles from "./Sidebar.module.css";
import { NavItem } from "./ui/NavItem/NavItem";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import profile from "../../assets/img/address-book.svg";
import creditCard from "../../assets/img/credit-card-simple.svg";
import fadersHorizontal from "../../assets/img/faders-horizontal.svg";
import globe from "../../assets/img/globe.svg";
import admin from "../../assets/img/adminIcon.svg";
import { useUserData } from "../../context/UserProvider";
import { ReactComponent as CardsIcon } from "../../assets/img/cards.svg";
const Sidebar = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { userData } = useUserData();

  const pagesAdmin = [
    {
      id: 1,
      name: t("Дашборд"),
      image: fadersHorizontal,
      href: "/",
    },
    {
      id: 2,
      name: t("Карты"),
      image: creditCard,
      href: "/maps",
    },
    {
      id: 3,
      name: t("Профиль"),
      image: profile,
      href: "/profile",
    },
    {
      id: 4,
      name: t("Админ"),
      image: admin,
      href: "/admin",
    },
    {
      id: 5,
      name: t("Прокладки"),
      image: globe,
      href: "/gaskets",
    },
  ];
  const pages = [
    {
      id: 1,
      name: t("Дашборд"),
      image: fadersHorizontal,
      href: "/",
    },
    {
      id: 2,
      name: t("Профиль"),
      image: profile,
      href: "/profile",
    },
  ];

  let pagesMap = userData?.role > 5 ? pagesAdmin : pages;

  return (
    <div className={styles[theme === "dark" ? "dark-theme" : "light-theme"]}>
      <h1 className={styles.name}>
        <CardsIcon className={styles.icon} />
      </h1>
      <ul className={styles.list}>
        {userData?.role &&
          pagesMap.map(({ id, name, image, href }) => {
            return (
              <li key={id} className={styles.item}>
                <NavItem icon={image} href={href}>
                  {name}
                </NavItem>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Sidebar;
