import React from "react";
import styles from "./UserSkeleton.module.css";
import { useTheme } from "../../../../../../components/ui/ChangeTheme/context/ThemeContext";
const UserSkeleton = () => {
  const { theme } = useTheme();
  return (
    <div className={styles[theme === "dark" ? "dark_skeleton" : "skeleton"]}>
      <div className={styles.skeleton_user}>
        <div className={styles.skeleton_user_info}></div>
        <div></div>
        <div className={styles.skeleton_user_role}></div>
        <div className={styles.skeleton_interface}>
          <div className={styles.skeleton_icon}></div>
          <div className={styles.skeleton_icon}></div>
        </div>
      </div>
    </div>
  );
};

export default UserSkeleton;
