import React, { useEffect, useRef, useState } from "react";
import { DashboardTable } from "../DashboardTable/DashboardTable";
import styles from "./DashboardDomain.module.css";
import { Button } from "../../../../components/Button/Button";
import { ReactComponent as ButtonIcon } from "../../../../assets/img/domainButtonIcon.svg";
import classNames from "classnames";

import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { getData } from "../../api";
import Pagination from "../../../../components/Pagination/Pagination";

import { useTranslation } from "react-i18next";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { useUserData } from "../../../../context/UserProvider";
import Aside from "../../../../components/Aside/Aside";
import { AddDomain } from "../../../../components/AddDomain/AddDomain";

export const DashboardDomain = ({ openAside }) => {
  const { t } = useTranslation();
  const navData = [t("Основа"), t("Архив")];

  const { theme } = useTheme();
  const [navigation, setNavigation] = useState(navData[0]);
  const [dataTable, setDataTable] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPartner, setOptionsPartner] = useState([]);
  const [optionsWorker, setOptionsWorker] = useState([]);
  const [pageCountState, setPageCountState] = useState(0);
  const [partnerSelect, setPartnerSelect] = useState([]);
  const [devSelect, setDevSelect] = useState([]);
  const [inputDomainSearch, setInputDomainSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useUserData();
  const [isOpenAsideDomain, setIsOpenAsideDomain] = useState(false);
  const [fullLength, setFullLength] = useState(0);

  const handleOpenAsideDomain = () => {
    setIsOpenAsideDomain(true);
  };

  const handlePartnerChange = (value) => {
    if (typeof value === "object") {
      setOptionsPartner(value);
      return;
    }
    setOptionsPartner((prev) => {
      return prev.includes(value)
        ? prev.filter((el) => el !== value)
        : [...prev, value];
    });
  };

  const handleWorkerChange = (value) => {
    if (typeof value === "object") {
      setOptionsWorker(value);
      return;
    }
    setOptionsWorker((prev) => {
      return prev.includes(value)
        ? prev.filter((el) => el !== value)
        : [...prev, value];
    });
  };

  const getDomainsData = async (archive) => {
    setIsLoading(true);
    if (optionsPartner.length || optionsWorker.length) {
      const { data, page, pageCount, full_length } = await getData({
        url: "/filter-devs-partners-domains",
        body: {
          partners: optionsPartner.length ? optionsPartner : undefined,
          devs: optionsWorker.length ? optionsWorker : undefined,
          count: 10,
          queryData: currentPage,
        },
      });
      setFullLength(full_length);
      setPageCountState(pageCount);
      setData(data);
      setDataTable(data);
      setIsLoading(false);
    } else {
      const { data, page, pageCount, full_length } = await getData({
        url: "/get-my-domains",
        body: {
          archived: [archive],
          count: 10,
          queryData: currentPage,
        },
      });

      if (data.error) {
        console.log(data.error);
        return;
      }
      setFullLength(full_length);
      setPageCountState(pageCount);
      setData(data);
      setDataTable(data);
      setIsLoading(false);
    }
  };

  const getSelectData = async () => {
    if (userData?.role > 5) {
      const resultPartner = await getData({
        url: "/get-partners",
      });
      const partners = resultPartner.map((el) => ({
        label: el.username,
        value: el.username,
      }));

      setPartnerSelect(partners);
    }
    if (userData?.role > 5) {
      const resultDev = await getData({
        url: "/get-devs",
      });
      const devs = resultDev.map((el) => ({
        label: el.username,
        value: el.username,
      }));

      setDevSelect(devs);
    }
  };

  const handleSearchByDomain = async () => {
    if (!inputDomainSearch) {
      getDomainsData();
      return;
    }

    const result = await getData({
      url: "/search-by-domain",
      body: {
        domain: inputDomainSearch,
      },
    });

    if (result) {
      setDataTable(result);
      setPageCountState(0);
      setFullLength(result.length);
      return;
    }
    setDataTable([]);
  };

  useEffect(() => {
    getSelectData();
  }, []);

  useEffect(() => {
    if (navigation === t("Архив")) {
      getDomainsData(1);
    } else if (navigation === t("Основа")) {
      getDomainsData(0);
    }
  }, [optionsPartner, optionsWorker, currentPage, navigation]);

  // useEffect(() => {
  //   if (navigation === t("Архив")) {
  //     setDataTable(data.filter((el) => el.archived === 1));
  //   } else if (navigation === t("Основа")) {
  //     setDataTable(data);
  //   }
  // }, [navigation, data]);

  return (
    <div data-theme={theme} className={styles.container}>
      <div className={styles.header}>
        <h2>{t("Домены")}</h2>{" "}
        {userData?.role > 2 && (
          <Button onClick={handleOpenAsideDomain}>
            {t("Добавить домен")} <ButtonIcon style={{ marginLeft: "8px" }} />
          </Button>
        )}
      </div>
      <div className={styles.nav}>
        {navData.map((el) => (
          <button
            onClick={() => setNavigation(el)}
            key={el}
            className={classNames(navigation === el && styles.active)}
          >
            {el}
          </button>
        ))}
      </div>
      <div className={styles["wrapper-selects"]}>
        {userData?.role > 5 && (
          <CustomSelect
            selectAll
            selectRow={optionsPartner}
            data={optionsPartner}
            name={t("Партнер")}
            subName={t("Все партнеры")}
            options={partnerSelect}
            onChange={handlePartnerChange}
          />
        )}
        {userData?.role > 5 && (
          <CustomSelect
            selectAll
            selectRow={optionsWorker}
            data={optionsWorker}
            name={t("Дев")}
            subName={t("Все девы")}
            options={devSelect}
            onChange={handleWorkerChange}
          />
        )}
        <div className={styles.inputWrapper}>
          <label>{t("Домен")}</label>
          <input
            value={inputDomainSearch}
            onChange={(e) => setInputDomainSearch(e.target.value)}
            placeholder="domain.domain.org"
            type="text"
            className={styles.input}
          />
        </div>

        <Button onClick={handleSearchByDomain}>{t("Искать")}</Button>
      </div>
      <div className={styles["all-domains"]}>
        <span>
          {t("Всего доменов")}: {fullLength}
        </span>
      </div>
      <DashboardTable
        isLoading={isLoading}
        setDefaultData={setData}
        data={dataTable}
        setData={setDataTable}
        openAside={openAside}
      />{" "}
      <div className={styles["pagination-wrapper"]}>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={pageCountState}
        />
      </div>{" "}
      <Aside
        width={320}
        title={t("Добавление домена")}
        isOpen={isOpenAsideDomain}
        setIsOpen={setIsOpenAsideDomain}
      >
        <AddDomain fetchData={getDomainsData} />
      </Aside>
    </div>
  );
};
