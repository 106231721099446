import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from "recharts";
import { ChartChanger } from "../../../../components/ChartChanger/ChartCahnger";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { getData } from "../../api";
import moment from "moment";
import "moment/locale/ru";
import { useTranslation } from "react-i18next";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";

export const DashboardChart = () => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const [chartData, setChartData] = useState([]);

  const dataForChartChanger = [
    {
      name: t("День"),
      value: "day",
    },
    {
      name: t("Месяц"),
      value: "month",
    },
    {
      name: t("Год"),
      value: "year",
    },
    {
      name: t("Все время"),
      value: "all_time",
    },
  ];
  const [selectedChart, setSelectedChart] = useState(
    dataForChartChanger[dataForChartChanger.length - 1].value
  );

  useEffect(() => {
    const getChartData = async () => {
      const result = await getData({
        url: "/filter-full-graphs",
        body: {
          date: selectedChart,
        },
      });

      if (result.error) {
        console.log(result.error);
        return;
      }

      const formattedData = Object.keys(result)
        .filter((key) => key !== "total")
        .map((date) => ({
          value: result[date],
          time: moment(date).locale(i18n.language).format("D MMM"),
          originalDate: new Date(date),
        }));

      setChartData(
        formattedData.sort((a, b) => a.originalDate - b.originalDate)
      );
    };
    getChartData();
  }, [selectedChart]);

  const minBarWidth = 55;
  const chartWidth = chartData.length * minBarWidth;

  return (
    <div data-theme={theme} className={styles.container}>
      <div className={styles.header}>
        <h2>{t("График")}</h2>
        <ChartChanger
          items={dataForChartChanger}
          selectedValue={selectedChart}
          setSelectedValue={setSelectedChart}
        />
      </div>
      <div className={styles.wrapper_chart}>
        {chartData ? (
          <ResponsiveContainer
            style={{ marginTop: "46px" }}
            width={chartData.length > 20 ? chartWidth : "100%"}
            height={322}
          >
            <BarChart
              data={chartData}
              barCategoryGap={10}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 52,
              }}
            >
              {" "}
              <XAxis
                interval={0}
                axisLine={false}
                dataKey="time"
                tickLine={false}
                tick={<CustomizedTick />}
              />{" "}
              <Bar
                dataKey="value"
                fill="rgb(207, 188, 255)"
                stroke="black"
                minPointSize={32}
                maxBarSize={chartData.length < 20 ? "auto" : 32}
                strokeWidth={1}
                radius={4}
              >
                <LabelList
                  className={styles["chart-label"]}
                  dataKey="value"
                  position="top"
                />
              </Bar>
            </BarChart>{" "}
          </ResponsiveContainer>
        ) : (
          <Skeleton style={{ marginTop: "46px" }} width="100%" height={270} />
        )}
      </div>
    </div>
  );
};

const CustomizedTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={30} fill="#666" textAnchor="middle">
        {payload.value.split(" ").map((line, index) => (
          <tspan
            className={styles["tick-span"]}
            fontSize={16}
            key={index}
            x={0}
            dy={index === 0 ? 20 : 24}
          >
            {" "}
            {line}
          </tspan>
        ))}
      </text>{" "}
    </g>
  );
};
