import React, { useEffect, useRef, useState } from "react";
import styles from "./Modal.module.css";
import { Button } from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { ReactComponent as UrlIcon } from "../../../../assets/img/urlIcon.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/img/plus.svg";
import { v1 } from "uuid";
import axiosInstance from "../../../../utils/axiosInstance";
import SelectOne from "../../../../components/SelectOne/SelectOne";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { toast } from "react-toastify";
import { avatars } from "../../../../utils/Avatars/avatars";

export const Modal = ({ isAdmin, Roles, setRole, fetchData, setIsOpen }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [addDomainForms, setAddDomainForms] = useState({
    [v1()]: {
      login: "",
      password: "",
      role: "",
      avatar: "",
    },
  });

  const addForm = () => {
    setAddDomainForms((prev) => ({
      ...prev,
      [v1()]: {
        login: "",
        password: "",
        role: "",
        avatar: "",
      },
    }));
  };

  const handleSubmitData = async () => {
    try {
      Object.keys(addDomainForms).forEach(async (item) => {
        if (
          addDomainForms[item].login &&
          addDomainForms[item].password &&
          addDomainForms[item].role
        ) {
          await axiosInstance.post("/create", {
            login: addDomainForms[item].login,
            password: addDomainForms[item].password,
            role: addDomainForms[item].role,
            avatar: +addDomainForms[item].avatar,
          });
          fetchData();
        }
        setAddDomainForms({
          [v1()]: {
            login: "",
            password: "",
            role: "",
            avatar: "",
          },
        });
      });
      toast.success(t("Пользователь успешно добавлен!"));

      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(t("Произошла ошибка при добавлении пользователя"));
    }
  };

  return (
    <>
      <div className={styles.container} data-theme={theme}>
        <div className={styles["wrapper-block"]}>
          {Object.keys(addDomainForms).map((el, i) => {
            return (
              <FormDomain
                isAdmin={isAdmin}
                key={i}
                id={el}
                setAddDomainForms={setAddDomainForms}
                setRole={setRole}
                Roles={Roles}
              />
            );
          })}
          <button onClick={addForm} className={styles["add-button"]}>
            <PlusIcon />
          </button>
        </div>

        <div className={styles["container-buttons"]}>
          <Button
            className={styles["cancel-button"]}
            onClick={() =>
              setAddDomainForms({
                [v1()]: {
                  login: "",
                  password: "",
                  role: "",
                  avatar: "",
                },
              })
            }
          >
            {t("Отменить")}
          </Button>
          <Button
            onClick={() => {
              handleSubmitData();
            }}
          >
            {t("Сохранить")}
          </Button>
        </div>
      </div>
    </>
  );
};

const FormDomain = ({ id, setAddDomainForms, Roles }) => {
  const { t } = useTranslation();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [selectPartner, setSelectPartner] = useState([]);
  const [avatar, setAvatar] = useState("");
  const { theme } = useTheme();

  const optionsAvatars = Object.keys(avatars).map((el) => ({
    value: el,
    label: (
      <>
        {avatars[el]} Avatar {el}
      </>
    ),
  }));

  useEffect(() => {
    setAddDomainForms((prev) => {
      return {
        ...prev,
        [id]: {
          login: login,
          password: password,
          role: selectPartner,
          avatar: avatar,
        },
      };
    });
  }, [login, password, selectPartner, avatar]);

  return (
    <div className={styles.block} data-theme={theme}>
      <div className={styles["container-input"]}>
        <input
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          placeholder={t("Логин")}
          type="text"
          className={styles["input-domain"]}
        />
        <UrlIcon className={styles.urlIcon} />
      </div>
      <div className={styles["container-input"]}>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t("Пароль")}
          type="text"
          className={styles["input-domain"]}
        />
        <UrlIcon className={styles.urlIcon} />
      </div>

      <SelectOne
        className={styles.select}
        options={Roles}
        subName={t("Выбрать роль")}
        onChange={(e) => {
          setSelectPartner(e);
        }}
      />
      <SelectOne
        className={styles.select}
        options={optionsAvatars}
        subName={t("Выбрать аватар")}
        onChange={(e) => {
          setAvatar(e);
        }}
      />
    </div>
  );
};
