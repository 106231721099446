import React from "react";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";

export const TableSkeleton = () => {
  return (
    <tr>
      <td>
        <Skeleton width="100%" height={20} />
      </td>
      <td>
        {" "}
        <Skeleton width="100%" height={20} />
      </td>
      <td>
        <Skeleton width="100%" height={20} />
      </td>
      <td>
        <Skeleton width="100%" height={20} />
      </td>
      <td>
        {" "}
        <Skeleton width="100%" height={20} />
      </td>
      <td>
        <Skeleton width="100%" height={20} />
      </td>
      <td>
        <div style={{ position: "relative" }}>
          <button>
            <Skeleton width={20} height={20} />
          </button>
          <button>
            <Skeleton width={20} height={20} />
          </button>
          <button>
            <Skeleton width={20} height={20} />
          </button>
        </div>
      </td>
    </tr>
  );
};
