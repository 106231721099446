import React, { useEffect, useState } from "react";
import Table from "./Table/Table";
import { Button } from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as ButtonHeaderIcon } from "../../../assets/img/globe.svg";
import Search from "./Search/Search";
import TableInfo from "./TableInfo/TableInfo";
import axiosInstance from "../../../utils/axiosInstance";
import Aside from "../../../components/Aside/Aside";
import ModalOfAddPads from "./ModalOfAddPads";
import Pagination from "../../../components/Pagination/Pagination";
import { RowPerPage } from "../../../components/RowPerPage/RowPerPage";
import { useTheme } from "../../../components/ui/ChangeTheme/context/ThemeContext";
import { useUserData } from "../../../context/UserProvider";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Modal from "./Modal/Modal";

const getProklads = async (count, queryData) => {
  try {
    const { data } = await axiosInstance({
      url: "/get-proklads",
      method: "post",
      data: { count: count, queryData: queryData },
    });
    return data.message;
  } catch (err) {
    console.log(err);
  }
};

const deleteProklad = async (ccsData) => {
  try {
    const { data } = await axiosInstance({
      url: "/delete-proklads",
      method: "post",
      data: { ccsData },
    });
    return data.message;
  } catch (err) {
    return err;
  }
};

const rowsPerPageOptions = [5, 10, 20];

const ProkladsPage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [allProklads, setAllProklads] = useState([]);
  const [proklads, setProklads] = useState([]);
  const [fullLengthProklads, setFullLengthProklads] = useState(0);
  const [isOpenAside, setIsOpenAside] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [pageCountState, setPageCountState] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { userData } = useUserData();
  const [checkData, setCheckData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectrdRowChange = (value) => {
    setSelectedRow((prev) => {
      return prev.includes(value)
        ? prev.filter((el) => el !== value)
        : [...prev, value];
    });
  };

  const fetchDataProklads = async () => {
    setIsLoading(true);
    const result = await getProklads(rowsPerPage, currentPage);

    if (result.data) {
      setProklads(result.data);
      setFullLengthProklads(result.full_length);
      setPageCountState(result.pageCount);
    }
    setIsLoading(false);
  };

  const handleDeleteProklads = async (ccsData) => {
    const result = await deleteProklad(ccsData);
    if (result === "deleted") {
      toast.success(t("Успех!"));
      fetchDataProklads();
      setSelectedRow([]);
      return;
    }

    toast.error(t("Ошибка!"));
  };

  const getAllProklads = async () => {
    try {
      const { data } = await axiosInstance({
        url: "/get-proklads",
        method: "post",
        data: { all: true },
      });
      setAllProklads(data.message.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleHeaderCheckboxChange = async (isChecked) => {
    if (isChecked) {
      setSelectedRow(() => allProklads.map((el) => el.host));
    } else {
      setSelectedRow([]);
    }
  };

  useEffect(() => {
    if (userData?.role > 5) {
      fetchDataProklads();
      getAllProklads();
      return;
    }
  }, [rowsPerPage, currentPage]);

  return (
    <>
      <ToastContainer theme={theme} position="top-center" />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      {userData?.role > 5 ? (
        <div data-theme={theme} className={styles.container}>
          <div className={styles.header}>
            <h2>{t("Список прокладок")}</h2>{" "}
            <div className={styles["button-wrapper"]}>
              <Button onClick={() => setIsModalOpen(true)}>
                {t("Проверить прокладки")}
              </Button>
              <Button onClick={() => setIsOpenAside(true)}>
                {t("Добавить прокладку")}{" "}
                <ButtonHeaderIcon
                  fill={theme === "dark" ? "black" : "#49454F"}
                  style={{ marginLeft: "8px" }}
                />
              </Button>
            </div>
          </div>
          <Search
            setIsLoading={setIsLoading}
            setProklads={setProklads}
            fetchDataProklads={fetchDataProklads}
          />
          <TableInfo
            allProklads={allProklads}
            selectedRow={selectedRow}
            deleteProklad={handleDeleteProklads}
            fullLength={fullLengthProklads}
          />
          <Table
            isLoading={isLoading}
            allProkladsLength={allProklads.length}
            handleHeaderCheckboxChange={handleHeaderCheckboxChange}
            getAllProklads={getAllProklads}
            rowsPerPage={rowsPerPage}
            onChange={handleSelectrdRowChange}
            data={proklads}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            handleDeleteProklads={handleDeleteProklads}
          />
          <div className={styles["pagination-wrapper"]}>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={pageCountState}
            />
            <div className={styles["row-per-page-wrapper"]}>
              {" "}
              <RowPerPage
                options={rowsPerPageOptions}
                selectedValue={rowsPerPage}
                onChange={setRowsPerPage}
              />
            </div>
          </div>
          <Aside
            title={t("Добавление прокладки")}
            isOpen={isOpenAside}
            setIsOpen={setIsOpenAside}
            width={320}
          >
            <ModalOfAddPads
              fetchDataProklads={fetchDataProklads}
              onClose={() => setIsOpenAside(false)}
            />
          </Aside>
        </div>
      ) : (
        <h1>{t("Доступ запрещен")}</h1>
      )}
    </>
  );
};

export default ProkladsPage;
