import Avatar1 from "../../assets/img/avatar11.webp";
import Avatar2 from "../../assets/img/avatar2.webp";
import Avatar3 from "../../assets/img/avatar3.webp";
import Avatar4 from "../../assets/img/avatar4.webp";
import Avatar5 from "../../assets/img/avatar5.webp";
import Avatar6 from "../../assets/img/avatar6.webp";
import Avatar7 from "../../assets/img/avatar7.webp";
import Avatar8 from "../../assets/img/avatar8.webp";
import Avatar9 from "../../assets/img/avatar9.webp";
import Avatar10 from "../../assets/img/avatar10.webp";

export const avatars = {
  0: <img src={Avatar1} alt="avatar" />,
  1: <img src={Avatar2} alt="avatar" />,
  2: <img src={Avatar3} alt="avatar" />,
  3: <img src={Avatar4} alt="avatar" />,
  4: <img src={Avatar5} alt="avatar" />,
  6: <img src={Avatar6} alt="avatar" />,
  7: <img src={Avatar7} alt="avatar" />,
  8: <img src={Avatar8} alt="avatar" />,
  9: <img src={Avatar9} alt="avatar" />,
};
