import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import styles from "./Modal.module.css";
import axiosInstance from "../../../../utils/axiosInstance";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "../../../../assets/img/close.svg";

const Modal = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [data, setData] = useState([]);
  const handleOverlayClick = (e) => {
    const targetClass = e.target.className || "";
    if (
      typeof targetClass === "string" &&
      (targetClass.includes(styles.overlay) ||
        targetClass.includes(styles.close))
    ) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    async function checkProxy() {
      try {
        const { data } = await axiosInstance({
          url: "/check-proklads",
          method: "post",
          data: {},
        });
        let proxy = [];
        Object.keys(data.message).forEach((el) => {
          if (el !== "total_invalid" && el !== "total_valid") {
            proxy.push({ [el]: data.message[el] });
          }
        });
        setData(proxy);
      } catch (err) {
        console.log(err);
      }
    }
    checkProxy();
  }, []);
  return (
    <>
      {isModalOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.modal} data-theme={theme}>
            <div className={styles["title-container"]}>
              <h2 className={styles.title}>{t("Список прокладок")}</h2>
              <button
                className={styles.close}
                onClick={() => setIsModalOpen(false)}
              >
                <CloseIcon stroke={theme === "dark" ? "white" : "black"} />
              </button>
            </div>
            <div className={styles["data-container"]}>
              {data?.map((el) => (
                <div className={styles["info-container"]}>
                  <h3>{Object.keys(el)[0]}</h3>{" "}
                  <div
                    className={classNames(styles["host-circle"], {
                      [styles.isActive]: Object.values(el)[0],
                    })}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
