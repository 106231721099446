import React, { useState, useRef, useEffect } from "react";
import styles from "./geoRadio.module.css";
import classNames from "classnames";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";
import NoCountry from "../../../../../components/NoCountry/NoCountry";
const GeoRadio = ({ placeholder, svgIcon, items, setSelectedValues }) => {
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();
  const { t } = useTranslation();
  useEffect(() => {
    const country = selectedDomains
      .filter((domain) => domain.action === "include")
      .map((domain) => domain.id);

    const exclude_country = selectedDomains
      .filter((domain) => domain.action === "exclude_country")
      .map((domain) => domain.id);

    setSelectedValues((prev) => ({
      ...prev,
      geo: { country, exclude_country },
    }));
  }, [selectedDomains, setSelectedValues]);

  const handleDomainChange = (id, action) => {
    setSelectedDomains((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === id);
      let newDomains;

      if (existingIndex > -1) {
        if (prev[existingIndex].action === action) {
          newDomains = prev.filter((item) => item.id !== id);
        } else {
          newDomains = [...prev];
          newDomains[existingIndex].action = action;
        }
      } else {
        newDomains = [...prev, { id, action }];
      }

      return newDomains;
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const includeCount = selectedDomains.filter(
    (domain) => domain.action === "include"
  ).length;

  const excludeCount = selectedDomains.filter(
    (domain) => domain.action === "exclude_country"
  ).length;

  return (
    <div
      className={styles["dropdown-container"]}
      ref={dropdownRef}
      data-theme={theme}
    >
      <div
        className={styles["dropdown-header"]}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          border:
            isOpen || selectedDomains.length > 0 ? "1px solid #1E1E1E" : "",
          transition: "all 0.3s ease",
        }}
      >
        <span>{placeholder}</span>

        {includeCount > 0 && (
          <div
            className={styles["selected-count"]}
            style={{ right: excludeCount === 0 ? "40px" : "65px" }}
          >
            {includeCount}
          </div>
        )}

        {excludeCount > 0 && (
          <div
            className={classNames(styles["selected-count"], styles["exclude"])}
          >
            {excludeCount}
          </div>
        )}

        <button
          className={styles["clear-btn"]}
          onClick={() => {
            setSelectedDomains([]);
            setSelectedValues((prev) => ({
              ...prev,
              geo: { include: [], exclude_country: [] },
            }));
          }}
        >
          {isOpen || selectedDomains.length > 0 ? "✕" : svgIcon}
        </button>
      </div>

      {isOpen && (
        <div className={styles["dropdown-body"]}>
          <table className={styles["dropdown-table"]}>
            <thead>
              <tr>
                <th>{t("Гео")}</th>
                <th style={{ width: "110px" }}>
                  {t("Включить")} ({includeCount})
                </th>
                <th style={{ width: "110px" }}>
                  {t("Исключить")} ({excludeCount})
                </th>
              </tr>
            </thead>
            <tbody>
              {items?.map((county) => (
                <tr key={county} className={styles["dropdown-item"]}>
                  <td className={styles["domain-name"]}>
                    {county ? (
                      <img
                        src={`https://flagsapi.com/${county}/flat/32.png`}
                        style={{ borderRadius: "12px" }}
                        alt={county}
                      />
                    ) : (
                      <NoCountry
                        width={32}
                        height={30}
                        className={styles["no-country"]}
                        style={{
                          fill: theme === "dark" ? "#fff" : "#000",
                          stroke: theme === "dark" ? "#fff" : "#000",
                          height: "30px",
                        }}
                      />
                    )}

                    {county ? county : "N/A"}
                  </td>
                  <td>
                    <label className={styles["custom-radio"]}>
                      <input
                        type="radio"
                        name={`domain-${county}`}
                        checked={selectedDomains.some(
                          (d) => d.id === county && d.action === "include"
                        )}
                        onChange={() => handleDomainChange(county, "include")}
                      />
                      <span className={styles["radio-checkmark"]}></span>
                    </label>
                  </td>
                  <td>
                    <label className={styles["custom-radio"]}>
                      <input
                        type="radio"
                        name={`domain-${county}`}
                        checked={selectedDomains.some(
                          (d) =>
                            d.id === county && d.action === "exclude_country"
                        )}
                        onChange={() =>
                          handleDomainChange(county, "exclude_country")
                        }
                      />
                      <span className={styles["radio-checkmark"]}></span>
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GeoRadio;
