import styles from "./ChartChanger.module.css";
import { ReactComponent as CheckMark } from "../../assets/img/check-mark.svg";

export const ChartChanger = ({ items, selectedValue, setSelectedValue }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className={styles["radio-container"]}>
      {items.map((item, i) => {
        return (
          <label
            key={item + i}
            className={`${styles["custom-radio"]} ${
              selectedValue === item.value ? styles["selected"] : ""
            }`}
          >
            <input
              type="radio"
              value={item.value}
              checked={selectedValue === item.value}
              onChange={handleChange}
            />

            {selectedValue === item.value ? (
              <CheckMark style={{ marginRight: "5px" }} />
            ) : null}
            {item.name}
          </label>
        );
      })}
    </div>
  );
};
