import React, { useEffect, useRef, useState } from "react";
import styles from "./OtherNotes.module.css";
import { ReactComponent as DotsIcon } from "../../assets/img/dotsDashboardIcon.svg";
import icon from "../../assets/img/22.svg";
import { ReactComponent as EditIcon } from "../../assets/img/editNotesIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/img/removeNotesIcon.svg";
import { Button } from "../Button/Button";
import { getData } from "../../modules/DashboardPage/api";
import { Notification } from "../ui/Notification/Notification";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const OtherNotes = ({
  data,
  setData,
  theme,
  typeRequest,
  refRequest,
  avatars,
  userData,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditText, setEditText] = useState(false);
  const [text, setText] = useState(data.comment);

  const textareaRef = useRef(null);
  const oldTextRef = useRef(null);
  const { t } = useTranslation();
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleSubmitText = async () => {
    const result = await getData({
      url: "/edit-comment",
      body: {
        type: typeRequest,
        comment: text,
        commentId: data.comment_id,
      },
    });

    if (result.error) {
      toast.error(t("Ошибка!"));
      return;
    }

    toast.success(t("Успех!"));
    setEditText(false);
  };

  const handleCancelText = () => {
    setEditText(false);
    setText(oldTextRef.current);
  };

  const openEditText = () => {
    oldTextRef.current = text;
    setEditText(true);
  };

  const deleteNote = async () => {
    const result = await getData({
      url: "/delete-comment",
      body: {
        type: typeRequest,
        commentId: data.comment_id,
        ref: refRequest,
      },
    });

    if (result.error) {
      toast.error(t("Ошибка!"));
      return;
    }

    setData((prev) => prev.filter((el) => el.comment_id !== data.comment_id));
    toast.success(t("Успех!"));
  };

  useEffect(() => {
    if (isEditText) {
      adjustHeight();
    }
  }, [isEditText]);

  useEffect(() => {
    adjustHeight();
  }, [text]);

  return (
    <div>
      <div data-theme={theme} className={styles.otherNotes}>
        <div className={styles.otherNotesHeader}>
          <div className={styles.otherNotesHeaderLeft}>
            <img src={icon} alt="User Avatar" className={styles.avatar} />
            <div className={styles.otherNotesHeaderLeftText}>
              <p>{data.comment_username}</p>
              <p> {moment(data.createdAt).fromNow()}</p>
            </div>
          </div>
          <DotsMenu openEditText={openEditText} deleteNote={deleteNote} />
        </div>

        <div className={styles.otherNoteText}>
          {!isEditText ? (
            <div>{text}</div>
          ) : (
            <>
              {" "}
              <textarea
                ref={textareaRef}
                className={styles.textarea}
                value={text}
                onChange={(e) => setText(e.target.value)}
              ></textarea>{" "}
            </>
          )}
        </div>
      </div>{" "}
      {isEditText && (
        <div className={styles.buttonsContainer}>
          <Button onClick={handleCancelText} className={styles.cancelButton}>
            {t("Отменить")}
          </Button>
          <Button onClick={handleSubmitText} className={styles.submitButton}>
            {t("Сохранить")}
          </Button>
        </div>
      )}
    </div>
  );
};
const DotsMenu = ({ openEditText, deleteNote }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const btnRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      btnRef.current &&
      !btnRef.current.contains(event.target)
    ) {
      toggleDropdown();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div
        ref={btnRef}
        onClick={() => setIsOpen((prev) => !prev)}
        className={styles.otherNotesHeaderRight}
      >
        <DotsIcon className={styles.dots} />
        {isOpen && (
          <div ref={dropdownRef} className={styles["dropdown-menu"]}>
            <div onClick={openEditText}>
              <EditIcon /> {t("Редактировать")}
            </div>
            <div onClick={deleteNote}>
              <RemoveIcon /> {t("Удалить")}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
