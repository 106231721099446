import React, { useState } from "react";
import styles from "./InputFilter.module.css";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";
import { ReactComponent as CloseSvg } from "../../../../../assets/img/close.svg";
const InputFilter = ({
  style,
  placeholder,
  svgIcon,
  name,
  setSelectedValues,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [keywords, setKeywords] = useState([]);
  const { theme } = useTheme();

  const handleKeyDown = (e) => {
    if (e.key === " " && inputValue.trim() && inputValue.endsWith(",")) {
      e.preventDefault();
      setKeywords((prev) => [...prev, inputValue.trim().slice(0, -1)]);
      setSelectedValues((prev) => {
        return {
          ...prev,
          [name]: Array.from(
            new Set([...prev[name], inputValue.trim().slice(0, -1)])
          ),
        };
      });
      setInputValue("");
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const removeKeyword = (keyword) => {
    setKeywords((prev) => prev.filter((k) => k !== keyword));
    setSelectedValues((prev) => {
      const updatedKeywords = prev[name].filter((k) => k !== keyword);
      return {
        ...prev,
        [name]: updatedKeywords,
      };
    });
  };

  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.inputContainer}>
        {keywords.map((keyword, index) => (
          <span key={index} className={styles.keyword}>
            {keyword}
            <button
              className={styles.closeButton}
              onClick={() => removeKeyword(keyword)}
            >
              <CloseSvg />
            </button>
          </span>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={keywords.length === 0 ? placeholder : ""}
          className={styles.input}
        />
        <div className={styles.ico}>{svgIcon}</div>
      </div>
    </div>
  );
};

export default InputFilter;
