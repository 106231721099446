import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router";
import "./assets/styles/reset.css";
import "./assets/styles/index.css";
import { ThemeProvider } from "./components/ui/ChangeTheme/context/ThemeContext";
import { UserProvider } from "./context/UserProvider";
import "./utils/i18n";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <UserProvider>
        <RouterProvider router={Router} />
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>
);
