import React, { useEffect, useRef } from "react";
import styles from "./Aside.module.css"; // Импортируем модульный CSS
import { ReactComponent as CloseIcon } from "../../assets/img/xIcon2.svg";
import classNames from "classnames";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
const Aside = ({ isOpen, title, setIsOpen, children, width }) => {
  const { theme } = useTheme();

  const handleOverlay = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.querySelector("body").style = "overflow-y:hidden";
      return;
    }
    document.querySelector("body").style = "overflow-y:auto";
  }, [isOpen]);

  return (
    <>
      <div
        onClick={handleOverlay}
        className={classNames(styles.wrapper, {
          [styles.open]: isOpen,
        })}
      ></div>
      <div
        data-theme={theme}
        style={{ right: `-${isOpen ? 0 : width}px`, width: `${width}px` }}
        className={styles.asidePanel}
      >
        <div className={styles.asideHeader}>
          <h2 style={{ maxWidth: 200 }}>{title}</h2>
          <button
            className={styles.closeAsideButton}
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={styles.asideContent}>{children}</div>
      </div>
    </>
  );
};

export default Aside;
