import React from "react";
import styles from "./Skeleton.module.css";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";

export const Skeleton = ({ width, height, style }) => {
  const { theme } = useTheme();

  return (
    <div
      style={{ width: width, height: height, ...style }}
      className={theme === "light" ? styles.skeleton : styles.dark_skeleton}
    ></div>
  );
};
