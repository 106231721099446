export function dateFormatter(date) {
  if (date) {
    const splited =
      date.split(",").length > 1 ? date.split(",") : date.split(" ");
    const splited1 = splited[0].split("/");
    const splited2 = splited[1].split(":");
    let returnStr = `${splited1[2]}-${
      +splited1[0] < 10 ? "0" + splited1[0] : splited1[0]
    }-${+splited1[1] < 10 ? "0" + splited1[1] : splited1[1]}ZZ${
      +splited2[0] < 10 ? "0" + splited2[0].replace(" ", "") : splited2[0]
    }:${splited2[1].replace(" AM", "").replace(" PM", "")}:${splited2[2]
      .replace(" AM", "")
      .replace(" PM", "")}`;
    return returnStr.replace(/\s/g, "").replace("ZZ", " ");
  } else {
    return "Not valid date";
  }
}

export const columnSortDomain = (data, sortKey, direction) => {
  const sortedData = [...data].sort((a, b) => {
    let valueA, valueB;

    switch (sortKey) {
      case "createdAt":
        valueA = new Date(a.createdAt);
        valueB = new Date(b.createdAt);
        break;
      case "last":
        valueA = new Date(a.last);
        valueB = new Date(b.last);
        break;
      case "availability":
        valueA = a.not_opened_count < 0 ? 0 : a.not_opened_count / a.total;
        valueB = b.not_opened_count < 0 ? 0 : b.not_opened_count / b.total;
        break;
      case "total":
        valueA = a.total;
        valueB = b.total;
        break;
      case "uniques":
        valueA = Math.abs(a.total - a.double);
        valueB = Math.abs(b.total - b.double);
        break;
      case "double":
        valueA = a.double;
        valueB = b.double;
        break;
      default:
        return 0;
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      return direction === "asc" ? valueA - valueB : valueB - valueA;
    }

    if (valueA instanceof Date && valueB instanceof Date) {
      return direction === "asc" ? valueA - valueB : valueB - valueA;
    }

    return 0;
  });

  return sortedData; // Обновляем состояние с отсортированными данными
};

export const validDate = (e) => {
  const input = e.target;
  let value = input.value.replace(/\D/g, ""); // Удаляем все нечисловые символы

  // Разделяем значение на части для дня, месяца и года
  let day = value.slice(0, 2);
  let month = value.slice(2, 4);
  const year = value.slice(4, 8);

  // Проверка дня
  if (day.length === 2) {
    if (parseInt(day) < 1) day = "01";
    else if (parseInt(day) > 31) day = "31";
  }

  // Проверка месяца
  if (month.length === 2) {
    if (parseInt(month) < 1) month = "01";
    else if (parseInt(month) > 12) month = "12";
  }

  // Форматируем ввод с точками
  let formattedValue = "";
  if (day) formattedValue += day;
  if (month) formattedValue += "." + month;
  if (year) formattedValue += "." + year;

  return formattedValue.slice(0, 10);
};
