import React from "react";
import { Outlet } from "react-router-dom";

import { useTheme } from "../components/ui/ChangeTheme/context/ThemeContext";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";

const AppLayout = () => {
  const { theme } = useTheme();
  return (
    <div id="app" data-theme={theme} className={theme}>
      <aside>
        <Sidebar />
      </aside>

      <div id="content">
        <header>
          <Header />
        </header>

        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
