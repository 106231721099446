import classNames from "classnames";
import { useEffect, useState } from "react";
import { ReactComponent as CheckImg } from "../../../../assets/img/check-mark.svg";
import { ReactComponent as CloseImage } from "../../../../assets/img/close.svg";
import { ReactComponent as DomainIcon } from "../../../../assets/img/domainButtonIcon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/img/adminChange.svg";
import { ReactComponent as Trash } from "../../../../assets/img/trash.svg";
import Pagination from "../../../../components/Pagination/Pagination";
import { Button } from "../../../../components/ui";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import axiosInstance from "../../../../utils/axiosInstance";
import { useGetDomains } from "./api/api";
import styles from "./Domains.module.css";
import { useTranslation } from "react-i18next";
import { AddDomain } from "../../../../components/AddDomain/AddDomain";
import Aside from "../../../../components/Aside/Aside";
import { RowPerPage } from "../../../../components/RowPerPage/RowPerPage";
import DomainSkeleton from "./DomainSkeleton/DomainSkeleton";
import SelectOne from "../../../../components/SelectOne/SelectOne";
import { getData } from "../../../../api";
import { toast } from "react-toastify";
const rowsPerPageOptions = [5, 10, 20];

export const Domains = ({ className }) => {
  const { theme } = useTheme();
  const [editingIndex, setEditingIndex] = useState(null);
  const [ownerDomain, setOwnerDomain] = useState("");
  const [totalString, setTotalString] = useState(rowsPerPageOptions[1]);
  const [page, setPage] = useState(1);
  const { data, length, pageCount, fetchData, isLoading } = useGetDomains(
    page,
    totalString
  );
  const [editedData, setEditedData] = useState(data);
  const { t } = useTranslation();
  const [isOpenAsideDomain, setIsOpenAsideDomain] = useState(false);
  const [devs, setDevs] = useState(null);
  const [outProklads, setOutProklads] = useState([]);
  const [inProklads, setInProklads] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [domainInfo, setDomainInfo] = useState("");

  const [createDomainData, setCreateDomainData] = useState({
    dev: "",
    domain_username: "",
    inData: "",
    outData: "",
  });

  const [updateDomainData, setUpdateDomainData] = useState({});

  const handleDomainChange = async (count) => {
    try {
      const data = await getData({
        url: "/edit-accepted-domain",
        body: {
          newDomain:
            updateDomainData.url !== domainInfo && domainInfo
              ? domainInfo
              : updateDomainData.url,
          dev:
            createDomainData.dev !== updateDomainData.dev &&
            createDomainData.dev
              ? createDomainData.dev
              : updateDomainData.dev,
          domain_username:
            createDomainData.domain_username !==
              updateDomainData.domain_username &&
            createDomainData.domain_username
              ? createDomainData.domain_username
              : updateDomainData.domain_username,
          domain: updateDomainData.domain,
          count: count || 0,
          inData:
            createDomainData.inData !== updateDomainData.in_data &&
            createDomainData.inData
              ? createDomainData.inData
              : updateDomainData.in_data,
          outData:
            createDomainData.outData !== updateDomainData.out_data &&
            createDomainData.outData
              ? createDomainData.outData
              : updateDomainData.out_data,
        },
      });
      if (
        createDomainData.domain_username !== updateDomainData.domain_username &&
        createDomainData.domain_username
      ) {
        const res = await getData({
          url: "/change-owner-domain",
          body: {
            domain: updateDomainData.domain,
            username: createDomainData.domain_username,
          },
        });
      }
      if (data.domain_status) {
        setCreateDomainData({
          dev: "",
          domain_username: "",
          inData: "",
          outData: "",
        });
        fetchData();
        toast.success(t("Домен успешно изменен!"));
      } else {
        toast.error(t("Произошла ошибка при изменении домена!"));
      }
    } catch (error) {
      toast.error(t("Произошла ошибка при изменении домена!"));
      console.log(error);
    }
  };

  const createOptions = (arr, type) => {
    return arr.map((el) => ({ label: el[type], value: el[type] }));
  };

  useEffect(() => {
    async function getDevs() {
      try {
        const domain = await getData({
          url: "/get-accepted-domain",
          body: {
            queryData: 1,
            count: 99999,
          },
        });
        setDevs(
          Array.from(new Set(domain.data.map((el) => el.domain_username))).map(
            (item) => ({
              value: item,
              label: item,
            })
          )
        );

        const { data: proklads } = await getData({
          url: "/get-proklads",
          body: {
            all: true,
          },
        });
        let inArr = [];
        let outArr = [];

        proklads.forEach((el) =>
          el.type === "in" || el.type === "IN"
            ? inArr.push(el)
            : outArr.push(el)
        );

        const workers = await getData({
          url: "get-devs",
          body: {},
        });
        setWorkers(workers);

        setInProklads(inArr);
        setOutProklads(outArr);
      } catch (error) {
        console.log(error);
      }
    }
    getDevs();
  }, []);

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const handleEdit = (index) => {
    setEditingIndex(index);
    setUpdateDomainData(editedData[index]);
  };

  const handleExit = () => {
    setCreateDomainData({
      dev: "",
      domain_username: "",
      inData: "",
      outData: "",
    });
    setEditingIndex(null);
  };

  const handleDeleteDomain = async (domainData) => {
    try {
      await axiosInstance.post("/delete-accepted-domain", {
        domain: domainData.domain,
      });
      setEditingIndex(null);
      toast.success(t("Домен удален!"));
      fetchData();
    } catch (error) {
      toast.success(t("Ошибка при удалении домена!"));
      console.log(error);
    }
  };
  return (
    <div>
      {length > 0 && devs && (
        <div>
          <Aside
            width={320}
            title={t("Добавление домена")}
            isOpen={isOpenAsideDomain}
            setIsOpen={setIsOpenAsideDomain}
          >
            <AddDomain isAdmin fetchData={fetchData} />
          </Aside>

          <div
            className={classNames(
              styles[theme === "dark" ? "dark_users_block" : "users_block"],
              className
            )}
            data-theneme={theme}
          >
            <h1 className={styles.block_name}>{t("Домены")}</h1>
            <div className={styles["domains_block_actions"]}>
              <div className={styles["all-domains"]}>
                <span>
                  {t("Всего доменов")}: {length}
                </span>
              </div>
              <div className={styles.button}>
                <Button
                  variant="outline"
                  onClick={() => setIsOpenAsideDomain(true)}
                  EndIcon={DomainIcon}
                >
                  {t("Добавить домен")}
                </Button>
              </div>
            </div>
            <div className={styles[theme === "dark" ? "dark_data" : "data"]}>
              <div
                className={styles[theme === "dark" ? "dark_header" : "header"]}
              >
                <div className={styles.header_content}>
                  <h2 className={styles.header_content_item}>URL</h2>
                  <h2 className={styles.header_content_item}>
                    {t("Владелец")}
                  </h2>
                  <h2 className={styles.header_content_item}>{t("Дев")}</h2>
                  <h2 className={styles.header_content_item}>
                    {t("In прокладка")}
                  </h2>
                  <h2 className={styles.header_content_item}>
                    {t("Out прокладка")}
                  </h2>
                  <div className={styles.header_content_item}></div>
                </div>
              </div>
              {isLoading
                ? Array.from({ length: totalString }).map((_, index) => (
                    <DomainSkeleton key={index} theme={theme} />
                  ))
                : editedData.map((domain, index) => (
                    <div
                      key={index}
                      className={
                        styles[theme === "dark" ? "dark_user" : "user"]
                      }
                    >
                      <div className={styles.user_info}>
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={domainInfo}
                            onChange={(e) => setDomainInfo(e.target.value)}
                            className={styles.editDomainInput}
                          />
                        ) : (
                          <h2>{domain.domain}</h2>
                        )}
                      </div>
                      <div className={styles.user_info}>
                        {editingIndex === index ? (
                          devs && (
                            <SelectOne
                              options={devs}
                              subName={domain.domain_username}
                              onChange={(e) =>
                                setCreateDomainData((prev) => ({
                                  ...prev,
                                  domain_username: e,
                                }))
                              }
                              className={styles.editDomain}
                            />
                          )
                        ) : (
                          <h2>{domain.domain_username}</h2>
                        )}
                      </div>
                      <div className={styles.user_info}>
                        {editingIndex === index ? (
                          <SelectOne
                            options={createOptions(workers, "username")}
                            subName={domain.dev || t("Нет")}
                            onChange={(e) =>
                              setCreateDomainData((prev) => ({
                                ...prev,
                                dev: e,
                              }))
                            }
                            className={styles.editDomain}
                          />
                        ) : (
                          <h2>{domain.dev || t("Нет")}</h2>
                        )}
                      </div>
                      <div className={styles.user_info}>
                        {editingIndex === index ? (
                          <SelectOne
                            options={createOptions(inProklads, "host")}
                            subName={domain.in_data || t("Нет")}
                            onChange={(e) =>
                              setCreateDomainData((prev) => ({
                                ...prev,
                                inData: e,
                              }))
                            }
                            className={styles.editDomain}
                          />
                        ) : (
                          <h2>{domain.in_data || t("Нет")}</h2>
                        )}
                      </div>
                      <div className={styles.user_info}>
                        {editingIndex === index ? (
                          <SelectOne
                            options={createOptions(outProklads, "host")}
                            subName={domain.out_data || t("Нет")}
                            onChange={(e) =>
                              setCreateDomainData((prev) => ({
                                ...prev,
                                outData: e,
                              }))
                            }
                            className={styles.editDomain}
                          />
                        ) : (
                          <h2>{domain.out_data || t("Нет")}</h2>
                        )}
                      </div>
                      <div className={styles.interface}>
                        {editingIndex === index ? (
                          <div className={styles.editButtons}>
                            <button
                              className={styles.save}
                              onClick={() => {
                                handleExit();
                                handleDomainChange(domain.count);
                              }}
                            >
                              <CheckImg
                                style={{
                                  stroke: "#7b818a",
                                  width: "18px",
                                }}
                              />
                            </button>
                            <button onClick={handleExit}>
                              <CloseImage
                                style={{
                                  stroke: "#7b818a",
                                  width: "30px",
                                }}
                              />
                            </button>
                            <button onClick={() => handleDeleteDomain(domain)}>
                              <Trash />
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className={styles.edit}
                              onClick={() => {
                                handleEdit(index);
                                setDomainInfo((prev) => domain.domain);
                              }}
                            >
                              <EditIcon />
                            </button>
                            <button onClick={() => handleDeleteDomain(domain)}>
                              <Trash />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
            <div className={styles.pagination_container}>
              <Pagination
                totalPages={pageCount}
                className={styles.pagination}
                currentPage={page}
                setCurrentPage={setPage}
              />
              <div className={styles.counter_block}>
                <RowPerPage
                  options={rowsPerPageOptions}
                  selectedValue={totalString}
                  onChange={setTotalString}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
