import { Outlet, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axiosInstance from "../../utils/axiosInstance"



const AuthLayout = () => {
    const [auth,setAuth] = useState(false)

    return (
        <div className="flex">
            <div className="max_w_1400_navbar">
                <Outlet />
            </div>
        </div>
    )
}

export default AuthLayout