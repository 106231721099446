import { useEffect, useRef, useState } from "react";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
import classNames from "classnames";
import styles from "./SelectOne.module.css";
import { ReactComponent as ArrowIcon } from "../../assets/img/selectArrow.svg";
import { useTranslation } from "react-i18next";

const SelectOne = ({
  selectAll,
  options,
  onChange,
  name,
  subName,
  icon,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const dropdownRef = useRef(null);
  const btnRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !btnRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    onChange(value);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const selectedOptionLabel = options.find(
    (option) => option.value === selectedValue
  )?.label;

  return (
    <div
      data-theme={theme}
      className={classNames(styles["custom-select"], className)}
      {...props}
    >
      <div ref={btnRef} className={styles.selected} onClick={toggleDropdown}>
        <div>
          <p>{name}</p>
          <p>{selectedOptionLabel || subName}</p>
        </div>
        {icon || <ArrowIcon className={styles.arrow} />}
      </div>
      {isOpen && (
        <div ref={dropdownRef} className={styles.options}>
          {selectAll && (
            <div className={styles.option} onClick={() => handleSelect("")}>
              <span>{t("Не выбрано")}</span>
            </div>
          )}
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.option}
              onClick={() => handleSelect(option.value)}
            >
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectOne;
