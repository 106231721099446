import { useState, useEffect } from "react";
import axiosInstance from "../../../../../utils/axiosInstance";

export const useGetDomains = (page = 1, count = 10) => {
  const [data, setData] = useState([]);
  const [length, setLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/get-accepted-domain", {
        queryData: page,
        count: count,
      });
      setLength(response.data.message.full_length);
      setData(response.data.message.data);
      setPageCount(response.data.message.pageCount);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, count]);

  return { data, length, pageCount, fetchData, isLoading };
};
