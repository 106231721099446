import React, { useState, useRef, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./endDateFilter.css";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";
import styles from "./Date.module.css";
import { handleInputChange, validDate } from "../../../../../utils/script";

const DateFilter = ({ placeholder, svgIcon, setSelectedValues }) => {
  const [inputValue, setInputValue] = useState("");
  const [inputRowValue, setInputRowValue] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const datePickerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();

  registerLocale(i18n.language, i18n.language === "ru" ? ru : en);

  const handleDateChange = (date) => {
    setInputValue(date);
    setInputRowValue(date);
  };

  const handleClearDate = () => {
    setInputValue("");
    setInputRowValue("");
  };

  useEffect(() => {
    setSelectedValues(
      inputValue ? moment(inputValue).format("DD.MM.YYYY") : ""
    );
  }, [inputValue]);

  const handleRowChange = (e) => {
    if (e.target.value === undefined) return;

    const value = validDate(e);

    setInputRowValue(value);
  };

  return (
    <div data-theme={theme} className={styles.wrapper}>
      <DatePicker
        className="calendar-proklads-add"
        calendarClassName="calendar-proklads-add"
        dateFormat={"dd.MM.yyyy"}
        placeholderText={t("мм.дд.гггг")}
        ref={datePickerRef}
        selected={inputValue}
        value={inputRowValue}
        onChangeRaw={handleRowChange}
        onChange={handleDateChange}
        locale={i18n.language}
        shouldCloseOnSelect={true}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
      ></DatePicker>{" "}
      {inputValue ? <span onClick={handleClearDate}>✕</span> : svgIcon}
    </div>
  );
};

export default DateFilter;
