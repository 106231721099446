import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateFilter.css";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import { Button } from "../../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../../components/ui/ChangeTheme/context/ThemeContext";
import styles from "./styles.module.css";
import classNames from "classnames";

const DateFilter = ({ placeholder, svgIcon, setSelectedValues }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startInputValue, setStartInputValue] = useState("");
  const [endInputValue, setEndInputValue] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [excludeStart, setExcludeStart] = useState(false);
  const [excludeEnd, setExcludeEnd] = useState(false);
  const datePickerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const [inputRowValue, setInputRowValue] = useState("");

  registerLocale(i18n.language, i18n.language === "ru" ? ru : en);

  const formatDateWithDots = (date) => {
    if (!date) return "";
    const day = `${date.getDate()}`.padStart(2, "0");
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleDateChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      if (!excludeStart && start) {
        setInputRowValue(start);
        setStartDate(start);
        setStartInputValue(formatDateWithDots(start));
      }
      if (!excludeEnd && end) {
        setInputRowValue(end);
        setEndDate(end);
        setEndInputValue(formatDateWithDots(end));
      } else {
        setEndDate(null);
        setEndInputValue("");
      }
    } else {
      if (!excludeStart) {
        setInputRowValue(dates);
        setStartDate(dates);
        setStartInputValue(formatDateWithDots(dates));
      }
      if (!excludeEnd) {
        setInputRowValue(dates);
        setEndDate(dates);
        setEndInputValue(formatDateWithDots(dates));
      }
    }
  };

  const handleApply = () => {
    handleApplyDate();
    datePickerRef.current.setOpen(false);
  };

  const handleCancel = () => {
    setStartDate(null);
    setEndDate(null);
    setStartInputValue("");
    setEndInputValue("");
    setExcludeStart(false);
    setExcludeEnd(false);
    setInputRowValue("");
    setSelectedValues((prev) => ({
      ...prev,
      dates: [],
    }));
  };

  const parseDateInput = (input) => {
    if (!input || typeof input !== "string" || input.split(".").length < 3)
      return null;
    const [day, month, year] = input.split(".");
    if (day > 31 || month > 12 || year.length !== 4) return null;
    const parsedDate = new Date(`${year}-${month}-${day}`);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const formatInputValue = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const length = cleanedValue.length;

    if (length < 3) return cleanedValue;
    if (length < 5)
      return `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(2)}`;
    return `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(
      2,
      4
    )}.${cleanedValue.slice(4, 8)}`;
  };

  const handleInputChange = (e, type) => {
    let { value } = e.target;
    const cleanedValue = value.replace(/\D/g, "");
    const formattedValue = formatInputValue(cleanedValue);
    const [day, month] = formattedValue.split(".");

    if (day && +day > 31) return;
    if (month && +month > 12) return;

    if (formattedValue.length > 10) return;

    if (type === "start") {
      setStartInputValue(formattedValue);
      const parsedDate = parseDateInput(formattedValue);
      setStartDate(parsedDate || null);
    } else if (type === "end") {
      setEndInputValue(formattedValue);
      const parsedDate = parseDateInput(formattedValue);
      setEndDate(parsedDate || null);
    }
  };

  const handleApplyDate = () => {
    const formatDate = (date) => {
      if (!date) return null;
      const d = new Date(date);
      const day = `${d.getDate()}`.padStart(2, "0");
      const month = `${d.getMonth() + 1}`.padStart(2, "0");
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    };

    let dates = [];

    if (excludeStart && endDate) {
      dates.push(`e${formatDate(endDate)}`);
    } else if (excludeEnd && startDate) {
      dates.push(`s${formatDate(startDate)}`);
    } else {
      if (startDate) dates.push(formatDate(startDate));
      if (endDate) dates.push(formatDate(endDate));
    }

    setSelectedValues((prev) => ({
      ...prev,
      dates: dates,
    }));
  };

  const v = (e) => {
    const input = e.target;
    let value = input.value.replace(/\D/g, "");

    let day1 = value.slice(0, 2);
    let month1 = value.slice(2, 4);
    let year1 = value.slice(4, 8);
    let day2 = value.slice(8, 10);
    let month2 = value.slice(10, 12);
    let year2 = value.slice(12, 16);

    if (excludeStart || excludeEnd) {
      day2 = "";
      month2 = "";
      year2 = "";
    }

    if (day1.length === 2) {
      day1 = Math.max(1, Math.min(parseInt(day1), 31))
        .toString()
        .padStart(2, "0");
    }
    if (month1.length === 2) {
      month1 = Math.max(1, Math.min(parseInt(month1), 12))
        .toString()
        .padStart(2, "0");
    }
    if (year1.length === 4) {
      year1 = parseInt(year1).toString().padStart(4, "0");
    }

    if (day2.length === 2) {
      day2 = Math.max(1, Math.min(parseInt(day2), 31))
        .toString()
        .padStart(2, "0");
    }
    if (month2.length === 2) {
      month2 = Math.max(1, Math.min(parseInt(month2), 12))
        .toString()
        .padStart(2, "0");
    }
    if (year2.length === 4) {
      year2 = parseInt(year2).toString().padStart(4, "0");
    }

    let formattedValue = "";
    if (day1) formattedValue += day1;
    if (month1) formattedValue += "." + month1;
    if (year1) formattedValue += "." + year1;

    if (day1 && month1 && year1 && (day2 || month2 || year2)) {
      formattedValue += " - ";
    }

    if (day2) formattedValue += day2;
    if (month2) formattedValue += "." + month2;
    if (year2) formattedValue += "." + year2;

    return formattedValue.slice(0, 23);
  };

  const handleRowChange = (e) => {
    if (e.target.value === undefined) return;

    const formattedValue = v(e);
    setInputRowValue(formattedValue);

    const splitValue = formattedValue.split(" - ");
    if (excludeStart) {
      setEndDate(splitValue[0] ? parseDateInput(splitValue[0]) : null);
      setStartDate(null);
    } else if (excludeEnd) {
      setStartDate(splitValue[0] ? parseDateInput(splitValue[0]) : null);
      setEndDate(null);
    } else {
      setStartDate(splitValue[0] ? parseDateInput(splitValue[0]) : null);
      setEndDate(splitValue[1] ? parseDateInput(splitValue[1]) : null);
      setEndInputValue(splitValue[1] ? formatInputValue(splitValue[1]) : null);
    }
  };

  return (
    <div className={styles["datepicker"]} data-theme={theme}>
      <span
        className={classNames(styles.placeholder, {
          [styles.active]:
            isCalendarOpen || startDate || endDate || inputRowValue,
        })}
      >
        {t("Период прихода карты")}
      </span>
      <DatePicker
        data-theme={theme}
        wrapperClassName={styles.wrapper}
        ref={datePickerRef}
        value={inputRowValue}
        onChangeRaw={handleRowChange}
        selected={excludeStart ? endDate : startDate}
        onChange={handleDateChange}
        startDate={excludeStart ? null : startDate}
        endDate={excludeEnd ? null : endDate}
        selectsRange={!excludeStart && !excludeEnd}
        dateFormat={
          excludeEnd
            ? `dd.MM.yyyy - ${t("н/в")}`
            : endDate && !startDate
            ? `${t("н/в")} - dd.MM.yyyy`
            : "dd.MM.yyyy"
        }
        monthsShown={2}
        calendarClassName={
          theme === "dark" ? "dark-theme-calendar" : "light-theme-calendar"
        }
        locale={i18n.language}
        disabledKeyboardNavigation
        shouldCloseOnSelect={false}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <div>
            <button
              aria-label="Previous Month"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                {"<"}
              </span>
            </button>
            <span className="react-datepicker__current-month">
              {monthDate
                .toLocaleString(i18n.language, { month: "long" })
                .charAt(0)
                .toUpperCase() +
                monthDate
                  .toLocaleString(i18n.language, { month: "long" })
                  .slice(1)}
            </span>
            <button
              aria-label="Next Month"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                {">"}
              </span>
            </button>
          </div>
        )}
      >
        <div className="data-container" data-theme={theme}>
          <div className="date-input-container">
            <input
              type="text"
              value={startInputValue || ""}
              className="datePickerInput"
              onChange={(e) => handleInputChange(e, "start")}
              placeholder={t("ДД.ММ.ГГГГ")}
              style={{
                background: "transparent",
                width: "120px",
                padding: "0 4px",
              }}
            />
            -
            <input
              type="text"
              value={endInputValue || ""}
              className="datePickerInput"
              onChange={(e) => handleInputChange(e, "end")}
              placeholder={t("ДД.ММ.ГГГГ")}
              style={{
                background: "transparent",
                width: "120px",
                padding: "0 4px",
              }}
            />
          </div>

          <div className="custom-checkbox-container">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={excludeStart}
                onChange={() => setExcludeStart((prev) => !prev)}
              />
              <span className="checkmark"></span>
              {t("не выбирать начало периода")}
            </label>

            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={excludeEnd}
                onChange={() => setExcludeEnd((prev) => !prev)}
              />
              <span className="checkmark"></span>
              {t("не выбирать конец периода")}
            </label>
          </div>
          <div className="btn-container">
            <Button
              onClick={handleCancel}
              style={{
                background: "transparent",
                height: "32px",
              }}
            >
              {t("Отменить")}
            </Button>
            <Button
              onClick={handleApply}
              style={{ height: "32px", color: "black" }}
            >
              {t("Применить")}
            </Button>
          </div>
        </div>
      </DatePicker>

      <div
        className={styles.icon}
        onClick={() => {
          handleCancel();
        }}
      >
        {isCalendarOpen || startDate || endDate ? "✕" : svgIcon}
      </div>
    </div>
  );
};

export default DateFilter;
