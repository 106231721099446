import styles from "./helpButton.module.css";
import helpImg from "../../../../assets/img/Help circle.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/img/Help circle.svg";
import classNames from "classnames";
import { useState } from "react";

export const HelpButton = ({ children, className }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={classNames(styles.container, className)}>
      {show && <div className={styles.help}>{children}</div>}
      <button
        className={styles.button}
        type="button"
        onMouseOver={() => setShow(true)}
        onMouseOut={() => setShow(false)}
      >
        <img src={helpImg} alt="help" />
      </button>
    </div>
  );
};
