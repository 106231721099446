import React from "react";
import styles from "./Loader.module.css";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";
const Loader = () => {
  const { theme } = useTheme();
  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.loader}></div>
    </div>
  );
};

export default Loader;
