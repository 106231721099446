import classNames from "classnames";
import styles from "./Input.module.css";
import { useEffect, useState } from "react";
import eyeOff from "../../../assets/img/Eye off.svg";
import eye from "../../../assets/img/Eye.svg";
import { useTheme } from "../ChangeTheme/context/ThemeContext";

export const Input = ({
  placeholder = "",
  value,
  EndIcon,
  firstIcon,
  className,
  type = "text",
  onChange,
  onClick,
  ...props
}) => {
  const [isShow, setIsShow] = useState(false);
  const { theme } = useTheme();

  return (
    <div
      className={classNames(className, styles.container)}
      {...props}
      data-theme={theme}
    >
      <div className={styles.inputWrapper}>
        <input
          placeholder=" "
          value={value}
          className={classNames(
            className,
            styles[theme === "dark" ? "dark-input" : "input"]
          )}
          type={isShow ? "text" : type}
          onChange={onChange}
          onClick={onClick}
        />
        <label className={styles[theme === "dark" ? "dark_label" : "label"]}>
          {placeholder}
        </label>
        {type === "password" && (
          <button
            type="button"
            onClick={() => setIsShow(!isShow)}
            className={styles.showPassword_button}
          >
            <img
              src={isShow ? eye : eyeOff}
              alt="eye"
              className={styles[theme === "dark" ? "eye_dark" : "eye"]}
            />
          </button>
        )}
        {EndIcon && (
          <EndIcon
            style={{ stroke: theme === "dark" ? "white" : "#1e1e1e" }}
            className={styles["endIcon"]}
          />
        )}
      </div>
    </div>
  );
};
