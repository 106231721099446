import axiosInstance from "../utils/axiosInstance";

export const getData = async ({ url, body }) => {
  try {
    const { data } = await axiosInstance({
      url: url,
      method: "post",
      data: body,
    });

    return data.message;
  } catch (err) {
    if (err.response?.data?.message) {
      return { error: err.response.data.message };
    }
    return { error: err.message };
  }
};
