import axiosInstance from "../../../utils/axiosInstance";

export const EditTokens = (token, id) => {
  const response = axiosInstance.post("/edit-tokens", {
    newToken: token,
    newUid: id,
  });

  return response.data;
};
