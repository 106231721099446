import { useEffect, useState } from "react";
import axiosInstance from "../../../../../utils/axiosInstance";

export const useGetUsers = (page = 1, count = 10) => {
  const [data, setData] = useState([]);
  const [length, setLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await axiosInstance.post("/users", {
        queryData: page,
        count: count,
      });
      setLength(response.data.full_length);
      setData(response.data.data);
      setPageCount(response.data.pageCount);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, count]);

  return { data, length, pageCount, fetchData, isLoading };
};
