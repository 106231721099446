import React, { forwardRef, useEffect, useState } from "react";
import styles from "./Input.module.css";
import { ReactComponent as DateIcon } from "../../../../assets/img/calendarDefault.svg";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { validDate } from "../../../../utils/script";

export const Input = ({ type, text, onChange, ...props }) => {
  const { i18n } = useTranslation();
  const [textState, setTextState] = useState(text);
  const { theme } = useTheme();
  const [inputValue, setInputValue] = useState("");

  const handleDateChange = (date) => {
    setInputValue(date);
    setTextState(date);
  };

  const handleRowChange = (e) => {
    if (e.target.value === undefined) return;

    const value = validDate(e);

    setTextState(value);
  };

  useEffect(() => {
    onChange(textState);
  }, [textState]);

  switch (type) {
    case "date":
      return (
        <div data-theme={theme} className={styles.date}>
          <DatePicker
            dateFormat={"dd.MM.yyyy"}
            calendarClassName="calendar-proklads-add"
            locale={i18n.language}
            selected={inputValue}
            value={textState}
            onChangeRaw={handleRowChange}
            onChange={handleDateChange}
          />
          {textState ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setInputValue("");
                setTextState("");
              }}
            >
              ✕
            </span>
          ) : (
            <DateIcon />
          )}
        </div>
      );
    case "text":
      return (
        <input
          data-theme={theme}
          value={textState}
          onChange={(e) => setTextState(e.target.value)}
          className={styles.text}
          type={type}
          {...props}
        />
      );
    default:
      return;
  }
};
