import React from "react";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";

export const SkeletonTable = () => {
  return (
    <tr>
      <td>
        <Skeleton width="100%" height="20px" />
      </td>
      <td>
        <Skeleton width="100%" height="20px" />
      </td>
      <td>
        <span>
          <Skeleton width="100%" height="20px" />
        </span>
      </td>
      <td>
        <div style={{ display: "flex" }}>
          <Skeleton width="20px" height="20px" />/
          <Skeleton width="20px" height="20px" />
        </div>
      </td>
      <td>
        <Skeleton width="100%" height="20px" />
      </td>
      <td>
        {" "}
        <Skeleton width="100%" height="20px" />
      </td>
      <td>
        {" "}
        <Skeleton width="100%" height="20px" />
      </td>
      <td>
        {" "}
        <Skeleton width="100%" height="20px" />
      </td>
      <td>
        <div>
          <button>
            <Skeleton width="20px" height="20px" />
          </button>
          <button style={{ position: "relative" }}>
            <Skeleton width="20px" height="20px" />
          </button>{" "}
        </div>
      </td>
    </tr>
  );
};
