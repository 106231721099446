import classNames from "classnames";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from "./NavItem.module.css";
import { useTheme } from "../../../ui/ChangeTheme/context/ThemeContext";
import { useTranslation } from "react-i18next";

export const NavItem = ({ className, children, icon, href }) => {
  const [active, setActive] = useState(false);
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    setActive(pathname === href);
  }, [pathname]);

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles[active ? "active" : "default"], className)}
      >
        <NavLink
          to={href}
          className={styles[theme === "dark" ? "dark_link" : "link"]}
        >
          <img
            src={icon}
            alt={children}
            className={classNames(
              styles[theme === "dark" ? "dark_img" : "img"],
              {
                [styles.dark_img_active]: active && theme === "dark",
              }
            )}
          />
          <h1
            className={classNames(styles.text, {
              [styles.text_active]: active && theme === "dark",
            })}
          >
            {children}
          </h1>
        </NavLink>
      </div>
    </div>
  );
};
