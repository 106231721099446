import React from "react";
import styles from "./Button.module.css";
import classNames from "classnames";
import { useTheme } from "../ui/ChangeTheme/context/ThemeContext";

export const Button = ({ children, className, ...props }) => {
  const { theme } = useTheme();
  return (
    <button
      data-theme={theme}
      className={classNames(styles.button, className)}
      {...props}
    >
      {children}
    </button>
  );
};
