import React, { useState } from "react";
import { ReactComponent as ButtonIcon } from "../../../../assets/img/download.svg";
import styles from "./MapsModal.module.css";
import { Button } from "../../../../components/Button/Button";
import { useUserData } from "../../../../context/UserProvider";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import { avatars } from "../../../../utils/Avatars/avatars";

const MapsModal = ({
  isModalOpen,
  setIsModalOpen,
  allDomains,
  downloadCSV,
}) => {
  const { userData } = useUserData();
  const { t } = useTranslation();
  const [format, setFormat] = useState(
    "cc|exp|cvv|name|address|city|zip|state|ua|phonenumber"
  );
  const [textAreaValue, setTextAreaValue] = useState("");
  const { theme } = useTheme();
  const handleOverlayClick = (e) => {
    if (e.target.className.includes(styles.overlay)) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.modal} data-theme={theme}>
            <h2 className={styles.title}>
              {t(`Выгрузка`) + " " + allDomains.length + " " + t("карт")}
            </h2>

            <div className={styles.content}>
              <label className={styles.label}>{t("Формат выгрузки")}:</label>
              <input
                type="text"
                value={format}
                className={styles.input}
                onChange={(e) => setFormat(e.target.value)}
              />

              <label className={styles.label}>{t("Комментарий")}:</label>
              <div className={styles["textArea-container"]}>
                <div className={styles["user-info"]}>
                  <div className={styles.avatar}>
                    {avatars[userData?.avatar]}
                  </div>
                  <h3>{userData.username}</h3>
                </div>
                <textarea
                  placeholder={t("Начните печатать...")}
                  className={styles.textarea}
                  value={textAreaValue}
                  onChange={(e) => setTextAreaValue(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.footer}>
              <Button
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  setIsModalOpen(false);
                  setFormat(
                    "cc|exp|cvv|name|address|city|zip|state|ua|phonenumber"
                  );
                  setTextAreaValue("");
                }}
                className={styles.cancelButton}
              >
                {t("Отменить")}
              </Button>
              <Button
                onClick={() => {
                  downloadCSV(textAreaValue, format);
                  setIsModalOpen(false);
                  setFormat(
                    "cc|exp|cvv|name|address|city|zip|state|ua|phonenumber"
                  );
                  setTextAreaValue("");
                }}
                className={styles.downloadButton}
              >
                <span>{t("Скачать")}</span>
                <ButtonIcon style={{ stroke: "#7B818A" }} />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MapsModal;
